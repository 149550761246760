import styled from 'styled-components';
import { Button } from '../common/Button';
import LinkHandler from '../common/LinkHandler';
import { FC } from 'react';
import { Translations } from 'src/utils/Translations';

const Wrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block: auto;
  text-align: center;
`;

const Headline = styled.h1`
  font-size: var(--font-size-headline-xxl);
  margin-block-end: 0.75rem;
`;

const Text = styled.h5`
  margin-block-end: 2rem;
  font-size: var(--font-size-headline-sm);
  text-transform: uppercase;
`;

const SubHeadline = styled.p`
  margin-block-end: 2rem;
  font-size: var(--font-size-body-lg);
  font-weight: 600;
`;

const StyledLinkHandler = styled(LinkHandler)`
  &:hover {
    text-decoration: none;
  }
`;

const Page404: FC = () => {
  return (
    <Wrapper>
      <Headline>{Translations.pageNotFound.headline}</Headline>

      <Text>{Translations.pageNotFound.text}</Text>

      <SubHeadline>{Translations.pageNotFound.subHeadline}</SubHeadline>

      <StyledLinkHandler to={'/'}>
        <Button theme={'secondary'}>{Translations.pageNotFound.buttonLabel}</Button>
      </StyledLinkHandler>
    </Wrapper>
  );
};

export default Page404;
