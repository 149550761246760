import { useRecoilState } from 'recoil';
import { AddressState } from '../../../states/AppData';
import styled from 'styled-components';
import { Form } from '../../common/form/Form';
import { useForm } from 'react-hook-form';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Checkbox from '../../common/form/Checkbox';
import { Button } from '../../common/Button';
import { BREAKPOINT_MD, BREAKPOINT_XL } from '../../../styles/Breakpoints';
import { useUserApi } from '../../../services/user';
import { IAddress } from '../../../types/address';
import AddressForm from './subcomponents/AddressForm';
import useToast from '../../../hooks/useToast';
import { Notifications } from '../../layout/app/Layout';
import LoadingSpinner from '../../common/LoadingSpinner';
import { Glass } from '../../../styles/Custom';

const Wrapper = styled.div<{ hasBilling: boolean }>`
  ${Glass};
  display: flex;
  padding: 2rem;

  ${BREAKPOINT_MD} {
    width: max-content;
  }

  ${BREAKPOINT_XL} {
    width: 100%;
    max-width: ${({ hasBilling }) => (hasBilling ? '1268px' : '638px')};
  }
`;

const StyledForm = styled(Form)<{ hasBilling: boolean }>`
  display: grid;
  width: 100%;

  ${BREAKPOINT_XL} {
    grid-template-rows: auto;
    grid-template-columns: repeat(${({ hasBilling }) => (hasBilling ? 2 : 1)}, min(560px, 1fr));
    transition: grid-template-columns 0.25s ease-in-out;
    column-gap: 3.5rem;
  }
`;

const AddressWrapper = styled.div`
  display: grid;
  gap: 0.875rem;
  margin-block-end: 2rem;

  ${BREAKPOINT_MD} {
    input {
      width: 280px;
    }
  }

  ${BREAKPOINT_XL} {
    grid-row: 1;

    input {
      width: auto;
    }
  }
`;

const AddressHeadline = styled.h4`
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
`;

const StyledCheckbox = styled(Checkbox)`
  place-self: flex-start;
  margin-block-end: 2rem;

  ${BREAKPOINT_XL} {
    grid-row: 2;
  }
`;

const SubmitButton = styled(Button)`
  width: 10rem;
  justify-content: center;

  ${BREAKPOINT_XL} {
    grid-row: 3;
  }
`;

interface IAddressForm {
  city: string;
  company: string;
  country: string;
  firstname: string;
  houseNumber: string;
  lastname: string;
  phone: string;
  postalCode: string;
  street: string;
  hasBilling: boolean;
  cityBilling?: string;
  companyBilling?: string;
  countryBilling?: string;
  firstnameBilling?: string;
  houseNumberBilling?: string;
  lastnameBilling?: string;
  phoneBilling: string;
  postalCodeBilling?: string;
  streetBilling?: string;
}

export default function AddressTab() {
  const [addressState, setAddressState] = useRecoilState(AddressState);

  const [loading, setLoading] = useState(false);

  const userApi = useUserApi();
  const { addToast, addErrorToast } = useToast();
  const notifications = useContext(Notifications);

  const { trigger, ...form } = useForm<IAddressForm>({
    defaultValues: {
      firstname: addressState?.shipping.firstname,
    },
  });
  const hasBilling = form.watch('hasBilling');

  const isValid = useMemo(() => form.formState.isValid, [form.formState]);

  const isChanged = useMemo(() => !!Object.keys(form.formState.dirtyFields).length, [form.formState]);

  const submitHandler = useCallback(
    async (formState: IAddressForm) => {
      setLoading(true);

      const shipping = {
        city: formState.city,
        company: formState.company,
        country: formState.country,
        firstname: formState.firstname,
        houseNumber: formState.houseNumber,
        lastname: formState.lastname,
        phone: formState.phone,
        postalCode: formState.postalCode,
        street: formState.street,
      };

      const billing = formState.hasBilling
        ? {
            city: formState.cityBilling,
            company: formState.companyBilling,
            country: formState.countryBilling,
            firstname: formState.firstnameBilling,
            houseNumber: formState.houseNumberBilling,
            lastname: formState.lastnameBilling,
            phone: formState.phoneBilling,
            postalCode: formState.postalCodeBilling,
            street: formState.streetBilling,
          }
        : shipping;

      try {
        const response = await userApi.updateAddress({
          billingEqualsShipping: !formState.hasBilling,
          shipping,
          billing: billing as IAddress,
        });
        setAddressState(response);
        if (notifications) {
          addToast({
            headline: notifications.successfulSaveAddressNotification.headline,
            text: notifications.successfulSaveAddressNotification.text,
            type: 'success',
            autoClose: 5000,
          });
        }
        form.reset();
      } catch (error) {
        if (notifications) {
          addErrorToast(notifications.failedSaveAddressNotification);
        }
        console.log('error saving address:', error);
      } finally {
        setLoading(false);
      }
    },
    [userApi, setAddressState, notifications, addToast, addErrorToast],
  );

  useEffect(() => {
    if (addressState) {
      form.setValue('city', addressState.shipping.city);
      form.setValue('company', addressState.shipping?.company || '');
      form.setValue('country', addressState.shipping.country);
      form.setValue('firstname', addressState.shipping.firstname);
      form.setValue('houseNumber', addressState.shipping.houseNumber);
      form.setValue('lastname', addressState.shipping.lastname);
      form.setValue('phone', addressState.shipping?.phone || '');
      form.setValue('postalCode', addressState.shipping.postalCode);
      form.setValue('street', addressState.shipping.street);
      form.setValue('hasBilling', !addressState.billingEqualsShipping);
      form.setValue('cityBilling', addressState.billing.city);
      form.setValue('companyBilling', addressState.billing?.company || '');
      form.setValue('countryBilling', addressState.billing.country);
      form.setValue('firstnameBilling', addressState.billing.firstname);
      form.setValue('houseNumberBilling', addressState.billing.houseNumber);
      form.setValue('lastnameBilling', addressState.billing.lastname);
      form.setValue('phoneBilling', addressState.billing?.phone || '');
      form.setValue('postalCodeBilling', addressState.billing.postalCode);
      form.setValue('streetBilling', addressState.billing.street);
      trigger();
    }
  }, [addressState]);

  return (
    <Wrapper hasBilling={hasBilling}>
      <StyledForm
        form={{ ...form, trigger } as any}
        onSubmit={(formState) => submitHandler(formState as IAddressForm)}
        hasBilling={hasBilling}>
        <AddressWrapper>
          <AddressHeadline>shipping address</AddressHeadline>
          <AddressForm disabled={loading} />
        </AddressWrapper>

        <StyledCheckbox name={'hasBilling'} optional disabled={loading}>
          Different billing address
        </StyledCheckbox>

        {hasBilling && (
          <AddressWrapper>
            <AddressHeadline>billing address</AddressHeadline>
            <AddressForm prefix={'billing'} disabled={loading} />
          </AddressWrapper>
        )}

        <SubmitButton disabled={!isValid || !isChanged || loading}>{loading ? <LoadingSpinner /> : 'Save Changes'}</SubmitButton>
      </StyledForm>
    </Wrapper>
  );
}
