import { useDiscordLogin } from 'react-discord-login';

export const useOAuthWindow = () => {
  const { buildUrl: buildDiscordUrl } = useDiscordLogin({
    clientId: process.env.REACT_APP_DISCORD_OAUTH_CLIENT_ID!,
    redirectUri: `${process.env.REACT_APP_OAUTH_REDIRECT_BASE_URL}discord`,
    responseType: 'code',
    scopes: ['identify', 'email'],
  });

  const buildAppleOAuthUrl = () => {
    return encodeURI(`https://appleid.apple.com/auth/authorize?client_id=${process.env.REACT_APP_APPLE_OAUTH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_OAUTH_REDIRECT_BASE_URL}apple&response_mode=query&response_type=code`);
  };

  const openWindow = (url: string) => {
    window.location.href = url;
  };

  return {
    openWindow,
    buildDiscordUrl,
    buildAppleOAuthUrl,
  };

};
