import styled, { css } from 'styled-components';
import { TabSwitchBody, TabSwitchHeadline, TabSwitchInnerContainer } from './subcomponents/TrainingTabSwitch';
import StatTile from '../dashboard/StatTile';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XXL } from '../../../styles/Breakpoints';
import { IRewardStatus } from '../../../types/rewards';
import Tooltip, { AbsoluteWrapper } from '../../common/Tooltip';
import { Translations } from '../../../utils/Translations';

const BonusWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;

const StyledTile = styled(StatTile)`
  ${BREAKPOINT_MD} {
    width: 100%;
  }
`;

const StreakWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem 0.75rem;

  ${BREAKPOINT_XXL} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const StreakItem = styled.div<{ isActive: boolean; isLocked: boolean }>`
  display: grid;
  gap: 0.25rem;
  place-content: center;
  text-align: center;
  flex-direction: column;
  padding-block: 0.75rem;
  border: 1px solid var(--color-signal-green);
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, 0.5);

  &:last-child {
    grid-column: 1 / 4;

    ${BREAKPOINT_XXL} {
      grid-column: 3 / 5;
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: #152722;
      box-shadow: 0 0 10px 0 rgba(16, 228, 164, 0.5), 0 0 6px 0 var(--color-signal-green);
      color: var(--color-signal-green);
    `};

  ${({ isLocked }) =>
    isLocked &&
    css`
      background: rgba(0, 0, 0, 0.5);
      color: var(--color-grey-800);
      border-color: var(--color-grey-800);
    `};
`;

const StreakValue = styled.h6`
  font-size: 1.75rem;
  line-height: 1.2;
  font-weight: bold;

  ${BREAKPOINT_LG} {
    font-size: 2rem;
  }
`;

const StreakLabel = styled.p`
  font-weight: 700;
  font-size: 0.75rem;
`;

const TooltipWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    font-size: 1.5rem;
  }

  ${AbsoluteWrapper} {
    top: calc(100% + 1rem);
    left: 0;
    width: 100%;
    max-width: 32rem;
  }
`;

const CustomTooltip = styled(Tooltip)`
  position: initial;
`;

interface IRewardsTabProps {
  rewardStatus?: IRewardStatus;
  loading: boolean;
}

export default function RewardsTab(props: IRewardsTabProps) {
  const { rewardStatus, loading } = props;

  return (
    <TabSwitchBody>
      <TabSwitchInnerContainer>
        <TabSwitchHeadline>today's bonus</TabSwitchHeadline>

        <BonusWrapper>
          <StyledTile label={'Login Streak'} name={'days'} value={rewardStatus?.currentDay || 0} loading={loading} />
          <StyledTile label={'Momentum Bonus'} name={'points'} value={rewardStatus?.currentPoints || 0} loading={loading} />
        </BonusWrapper>
      </TabSwitchInnerContainer>

      <TabSwitchInnerContainer>
        <TooltipWrapper>
          <TabSwitchHeadline>momentum streak</TabSwitchHeadline>
          <CustomTooltip translation={Translations.tooltip.momentum} />
        </TooltipWrapper>

        <StreakWrapper>
          {rewardStatus?.streak.map(({ points, isActive, days, isLocked }, index) => (
            <StreakItem key={index} isActive={isActive} isLocked={isLocked}>
              <StreakValue>{points} MP</StreakValue>
              <StreakLabel>{days} Day Streak</StreakLabel>
            </StreakItem>
          ))}
        </StreakWrapper>
      </TabSwitchInnerContainer>
    </TabSwitchBody>
  );
}
