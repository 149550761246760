import styled from 'styled-components';
import { useMemo } from 'react';
import { useCountdown } from '../../../../hooks/useCountdown';
import { DetailPageTag } from './DetailPageStyledComponents';

const Wrapper = styled(DetailPageTag)<{ itemAvailable: boolean }>`
  display: flex;
  gap: 1.5rem;
  background: ${(props) => (props.itemAvailable ? `var(--color-primary-750)` : `var(--color-grey-650)`)};
  color: ${(props) => (props.itemAvailable ? `var(--color-black-900)` : `var(--color-grey-600)`)};
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 600;
`;

const Span = styled.span`
  display: flex;
  gap: 1px;
  white-space: none;

  p {
    text-align: center;
    width: 1.25rem;
  }
`;

interface IDetailPageCountdownProps {
  startDate?: string;
  endDate?: string;
  availableAmount?: number;
}

export default function DetailPageCountdown(props: IDetailPageCountdownProps) {
  const { startDate, endDate, availableAmount } = props;

  const countdownDate = useMemo(() => {
    if (availableAmount === 0) {
      return { label: 'sold out', date: undefined };
    } else if (startDate && new Date(startDate).getTime() > new Date().getTime()) {
      return { label: 'starts in', date: startDate };
    } else if (endDate && new Date(endDate).getTime() > new Date().getTime()) {
      return { label: 'ends in', date: endDate };
    } else if (endDate && new Date(endDate).getTime() < new Date().getTime()) {
      return { label: 'sale ended', date: undefined };
    } else return undefined;
  }, [startDate, endDate, availableAmount]);

  const [days, hours, minutes, seconds] = useCountdown(countdownDate?.date || '');

  const isAvailable = useMemo(() => {
    if (availableAmount === 0) {
      return false;
    } else if (startDate && new Date(startDate).getTime() > new Date().getTime()) {
      return true;
    } else if (endDate && new Date(endDate).getTime() > new Date().getTime()) {
      return true;
    } else if (endDate && new Date(endDate).getTime() < new Date().getTime()) {
      return false;
    } else {
      return true;
    }
  }, [startDate, endDate, availableAmount]);

  if (countdownDate) {
    return (
      <Wrapper itemAvailable={isAvailable}>
        {countdownDate.label}
        {countdownDate?.date && (
          <Span>
            <p>{days}</p>
            <span>:</span>
            <p>{hours}</p>
            <span>:</span>
            <p>{minutes}</p>
            <span>:</span>
            <p>{seconds}</p>
          </Span>
        )}
      </Wrapper>
    );
  } else return <></>;
}
