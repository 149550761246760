import styled, { css } from 'styled-components';
import { InputHTMLAttributes } from 'react';
import Field, { useFieldError, useFieldProps } from './Field';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextInputStyles = css`
  border-radius: 0.625rem;
  border-width: 1px;
  border-color: var(--color-text-default);
  border-style: solid;
  padding: 0.625rem 0.75rem;
  line-height: 1;

  :disabled {
    color: var(--color-grey-650);
    border-color: var(--color-grey-650);
    box-shadow: none;
  }
`;

const Input = styled(Field)<{ hasError: boolean }>`
  ${TextInputStyles};
  border-color: var(${({ hasError }) => (hasError ? '--color-signal-red' : '--color-text-default')});
`;

export const InputHint = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  padding-inline: 0.875rem;
  line-height: 1;
  margin-block-start: 0.375rem;
`;

export const InputError = styled(InputHint)`
  color: var(--color-signal-red);
  margin-block-start: 0.375rem;
`;

interface ITextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  optional?: boolean;
  hint?: string;
}

const TextInput = (props: ITextInputProps) => {
  const { name, hint, optional = false, ...inputProps } = props;

  const { ref, ...fieldProps } = useFieldProps(name, optional);
  const error = useFieldError(name);

  return (
    <Wrapper>
      <Input {...fieldProps} {...inputProps} hasError={!!error?.message} optional={optional} forwardRef={ref} />

      {hint && <InputHint>{hint}</InputHint>}

      {error?.message && <InputError>{error.message}</InputError>}
    </Wrapper>
  );
};

export default TextInput;
