// General
import { FC, SVGProps } from 'react';

const TwitterIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.2516 6.04297H15.7484V10.3281H17.2477L17.2516 6.04297ZM13.1265 6.02344H11.6271V10.3125H13.1265V6.02344ZM6.74838 2L3 5.57031V18.4297H7.49806V22L11.2464 18.4297H14.249L21 12V2H6.74838ZM19.5006 11.2891L16.5019 14.1445H13.4994L10.8735 16.6445V14.1445H7.49806V3.42969H19.5006V11.2891Z"
      fill="currentColor" />
  </svg>
);

export default TwitterIcon;
