import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import ShopItemCard from '../cards/ShopItemCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { DashboardHeader, DashboardHeadline, DashboardLink } from './index';
import { BREAKPOINT_XXL } from '../../../styles/Breakpoints';
import { RaffleProps, ShopItemProps, useShopApi } from '../../../services/shop';
import RaffleTicketCard from '../cards/RaffleTicketCard';

const Wrapper = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-rows: min-content 1fr;

  ${BREAKPOINT_XXL} {
    grid-column: 1 / 12;
  }
`;

const SwiperWrapper = styled.div`
  display: grid;
  margin-inline: calc(-1 * var(--content-spacing));
`;

const StyledSwiper = styled(Swiper)`
  display: flex;
  width: 100%;
  padding-inline: var(--content-spacing);
`;

export interface IShopRecommendationProps {
  headline: string;
  showMoreLabel: string;
}

export default function ShopRecommendations(props: IShopRecommendationProps) {
  const { headline, showMoreLabel } = props;
  const [recommendations, setRecommendations] = useState<ShopItemProps[] | RaffleProps[]>([]);
  const shopApi = useShopApi();

  const fetchData = useCallback(async () => {
    try {
      const response = await shopApi.getRecommendations();

      setRecommendations((response as any).slice(0, 3));
    } catch (error) {
      console.log('error fetching recommendations for dashboard:', error);
    }
  }, [shopApi]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <DashboardHeader>
        <DashboardHeadline>{headline}</DashboardHeadline>

        <DashboardLink to={'/shop'}>{showMoreLabel}</DashboardLink>
      </DashboardHeader>

      <SwiperWrapper>
        <StyledSwiper
          slidesPerView={1.3}
          spaceBetween={16}
          breakpoints={{
            576: {
              slidesPerView: 2.1,
              spaceBetween: 16,
            },
            786: {
              slidesPerView: 3,
              spaceBetween: 16,
            },
          }}>
          {recommendations.map((item, index) => (
            <SwiperSlide key={index}>
              {(item as any)?.item ? <RaffleTicketCard raffle={item as RaffleProps} /> : <ShopItemCard item={item as ShopItemProps} />}
            </SwiperSlide>
          ))}
        </StyledSwiper>
      </SwiperWrapper>
    </Wrapper>
  );
}
