import { atom, useResetRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { INFT } from '../types/NFT';
import { RaffleTicketProps, ShopHistoryItemProps } from '../services/shop';
import { IAccount } from '@m3ta-collective/mooncourt-utilities-library';
import { TimeStampEffect } from './effects/TimeStampEffect';
import { IUserAddress } from '../types/address';
const { persistAtom } = recoilPersist();

interface ITimeEffect {
  timeStamp?: number;
}

interface INFTState extends ITimeEffect {
  nfts: INFT[] | null;
}

interface IBoosterNFT extends INFT {
  wallet: string;
}

interface IBoosterState extends ITimeEffect {
  nfts: IBoosterNFT[] | null;
}

interface IShopItemState extends ITimeEffect {
  items: ShopHistoryItemProps[] | null;
}

interface IRaffleTicketState extends ITimeEffect {
  items: RaffleTicketProps[] | null;
}

export interface IConnectedWallet {
  wallet: string;
  ballers: number;
  comets: number;
  booster: number;
}

export const BoosterState = atom<IBoosterState>({
  key: 'booster-state',
  default: {
    nfts: null,
  },
  effects: [TimeStampEffect],
});

export const BallerState = atom<INFTState>({
  key: 'baller-state',
  default: {
    nfts: null,
  },
  effects: [TimeStampEffect],
});

export const CometsState = atom<INFTState>({
  key: 'comets-state',
  default: {
    nfts: null,
  },
  effects: [TimeStampEffect],
});

export const ShopItemState = atom<IShopItemState>({
  key: 'shop-item-state',
  default: {
    items: null,
  },
  effects: [TimeStampEffect],
});

export const HistoryItemState = atom<{ items: ShopHistoryItemProps[] | null }>({
  key: 'history-item-state',
  default: {
    items: null,
  },
  effects: [TimeStampEffect],
});

export const RaffleTicketState = atom<IRaffleTicketState>({
  key: 'raffle-ticket-state',
  default: {
    items: null,
  },
  effects: [TimeStampEffect],
});

export const AuthTokenState = atom<{ token: string | null }>({
  key: 'auth-token-state',
  default: {
    token: null,
  },
  effects: [persistAtom],
});

export const AccountState = atom<IAccount | null>({
  key: 'account-state',
  default: null,
});

export const WalletState = atom<IConnectedWallet[] | null>({
  key: 'wallet-state',
  default: null,
});

export const AddressState = atom<IUserAddress | null>({
  key: 'address-state',
  default: null,
});

export const useResetUserState = function () {
  const resetBoosterState = useResetRecoilState(BoosterState);
  const resetBallerState = useResetRecoilState(BallerState);
  const resetCometState = useResetRecoilState(CometsState);
  const resetShopItemState = useResetRecoilState(ShopItemState);
  const resetRaffleTicketState = useResetRecoilState(RaffleTicketState);
  const resetAddressState = useResetRecoilState(AddressState);
  const resetAccountState = useResetRecoilState(AccountState);
  const resetAuthTokenState = useResetRecoilState(AuthTokenState);
  const resetWalletState = useResetRecoilState(WalletState);
  const resetHistoryItemState = useResetRecoilState(HistoryItemState);

  return function () {
    resetBoosterState();
    resetBallerState();
    resetCometState();
    resetShopItemState();
    resetRaffleTicketState();
    resetAddressState();
    resetAccountState();
    resetAuthTokenState();
    resetWalletState();
    resetHistoryItemState();
  };
};
