import styled from 'styled-components';
import { Translations } from 'src/utils/Translations';
import { useEffect, useMemo, useState } from 'react';
import { useAccountApi } from 'src/services/account';
import { Button } from 'src/components/common/Button';
import LinkHandler from 'src/components/common/LinkHandler';
import { useIsApiReady } from 'src/services/ApiProvider';
import { useRecoilValue } from 'recoil';
import { AccountState } from 'src/states/AppData';
import LoginModal from 'src/components/content/user/LoginModal';
import Wysiwyg from 'src/components/common/Wysiwyg';
import Mooncourt from 'src/components/icons/Mooncourt';

const Wrapper = styled.div`
  align-self: center;
  margin-block: auto;
  display: flex;
  max-width: 32rem;
  padding: 3rem 4.375rem;
  flex-direction: column;
  align-items: center;
  border-radius: 1.25rem;
  border: 1px solid var(--color-text-default);
  background: var(--color-text-highlight);
`;

const Logo = styled(Mooncourt)`
  color: var(--color-primary-750);
  font-size: 2rem;
  margin-block-end: 0.5rem;
`;

const Headline = styled.h2`
  font-size: var(--font-size-headline-md);
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin-block-end: 2rem;
`;

const Text = styled(Wysiwyg)`
  margin-block-end: 1rem;

  h4 {
    text-align: center;
    text-transform: uppercase;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

const StyledLinkHandler = styled(LinkHandler)`
  width: 100%;

  &:hover {
    text-decoration: none;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  justify-content: center;
`;

export default function NewsletterOptOut() {
  const isApiReady = useIsApiReady();
  const accountApi = useAccountApi();
  const accountState = useRecoilValue(AccountState);

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [step, setStep] = useState<'initial' | 'success' | 'error'>('initial');
  const [loading, setLoading] = useState(false);

  async function handleOptOut() {
    if (isApiReady) {
      setLoading(true);
      try {
        const response = await accountApi.newsletterOptOut();
        if (response.success) {
          setStep('success');
        } else {
          setStep('error');
        }
      } catch (error) {
        setStep('error');
        console.log('[ERROR] opt-in in to newsletter:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setOpenLoginModal(true);
    }
  }

  useEffect(() => {
    if (accountState?.newsletter?.externalId === null) {
      setStep('success');
    }
  }, [accountState]);

  const statusTranslations = useMemo(() => {
    switch (step) {
      case 'success':
        return Translations.newsletter.optOut.success;
      case 'error':
        return Translations.newsletter.optOut.error;
      default:
        if (isApiReady) {
          return Translations.newsletter.optOut.initial;
        } else {
          return Translations.newsletter.optOut.loginRequired;
        }
    }
  }, [step, isApiReady]);

  return (
    <>
      <Wrapper>
        <Logo />
        <Headline>{statusTranslations.headline}</Headline>
        <Text content={statusTranslations.text} />
        <ButtonWrapper>
          {step === 'initial' && (
            <>
              {isApiReady ? (
                <>
                  <StyledButton theme={'primary'} disabled={loading} onClick={handleOptOut}>
                    {Translations.newsletter.optOut.initial.buttonLabel.confirm}
                  </StyledButton>
                  <StyledLinkHandler to={'/'}>
                    <StyledButton theme={'secondary'} disabled={loading}>
                      {Translations.newsletter.optOut.initial.buttonLabel.cancel}
                    </StyledButton>
                  </StyledLinkHandler>
                </>
              ) : (
                <>
                  <StyledButton theme={'primary'} disabled={loading} onClick={handleOptOut}>
                    {Translations.newsletter.optOut.loginRequired.buttonLabel.confirm}
                  </StyledButton>
                  <StyledLinkHandler to={'/'}>
                    <StyledButton theme={'secondary'} disabled={loading}>
                      {Translations.newsletter.optOut.loginRequired.buttonLabel.cancel}
                    </StyledButton>
                  </StyledLinkHandler>
                </>
              )}
            </>
          )}
          {step === 'success' && (
            <>
              <StyledLinkHandler to={'/profile'}>
                <StyledButton theme={'primary'} disabled={loading}>
                  {Translations.newsletter.optOut.success.buttonLabel.confirm}
                </StyledButton>
              </StyledLinkHandler>
              <StyledLinkHandler to={'/'}>
                <StyledButton theme={'secondary'} disabled={loading}>
                  {Translations.newsletter.optOut.success.buttonLabel.cancel}
                </StyledButton>
              </StyledLinkHandler>
            </>
          )}
          {step === 'error' && (
            <StyledLinkHandler to={'/'}>
              <StyledButton theme={'secondary'} disabled={loading}>
                {Translations.newsletter.optOut.success.buttonLabel.cancel}
              </StyledButton>
            </StyledLinkHandler>
          )}
        </ButtonWrapper>
      </Wrapper>

      {openLoginModal && <LoginModal onClose={() => setOpenLoginModal(false)} />}
    </>
  );
}
