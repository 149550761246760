import { AxiosInstance } from 'axios';
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AuthTokenState } from '../states/AppData';

export interface IApiContextProps {
  apiInstance: AxiosInstance;
  isApiReady: boolean;
}

const ApiContext = createContext<IApiContextProps>({} as any);
export const useApi = () => useContext(ApiContext).apiInstance;
export const useIsApiReady = () => useContext(ApiContext).isApiReady;

export const ApiProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const authTokenState = useRecoilValue(AuthTokenState);
  const [context, setContext] = useState<IApiContextProps>({
    apiInstance: axios.create({
      baseURL: '/api', // process.env.REACT_APP_API_BASE_URL,
      headers: {
        'Accept-Language': 'en',
      },
    }),
    isApiReady: false,
  });

  useEffect(() => {
    if (authTokenState.token) {
      setContext({
        ...context,
        isApiReady: true,
      });
    } else {
      setContext({ ...context, isApiReady: false });
    }

    const authTokenHeaderInterceptor = context.apiInstance.interceptors.request.use((config: any) => {
      if (authTokenState.token) {
        config.headers.authorization = authTokenState.token;
      }

      return config;
    }, undefined);

    const blacklistedResponseInterceptor = context.apiInstance.interceptors.response.use(undefined, (error) => {
      if ([401, 403].includes(error?.response?.status)) {
        navigate('/');
      } else if (423 === error?.response?.status || error?.response?.data.blocked) {
        navigate('/access-blocked');
      } else {
        return Promise.reject(error);
      }
    });

    return () => {
      context.apiInstance.interceptors.request.eject(authTokenHeaderInterceptor);
      context.apiInstance.interceptors.response.eject(blacklistedResponseInterceptor);
    };
  }, [authTokenState]);

  return <ApiContext.Provider value={context}>{children}</ApiContext.Provider>;
};
