// General
import { FC, SVGProps } from 'react';

const SuccessIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.8321 22.5547L23.8321 10.5547L22.1679 9.4453L14.8154 20.4742L9.67572 15.7628L8.32428 17.2372L14.3243 22.7372L15.1846 23.5258L15.8321 22.5547Z'
      fill='var(--color-primary-750)'
    />
  </svg>
);

export default SuccessIcon;
