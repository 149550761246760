import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import ChevronIcon from '../../../icons/ChevronIcon';

const Wrapper = styled.div`
  grid-column: 1 / -1;
  margin-block-end: 1rem;
`;

const Button = styled.button`
  align-self: flex-start;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  font-size: 1.125rem;
  text-decoration: underline;
  font-weight: 700;

  svg {
    transform: rotate(90deg);

    path {
      stroke-width: 2px;
    }
  }

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary-750);
    }
  }
`;

export default function DetailBackButton() {
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <Wrapper>
      <Button onClick={navigateBack}>
        <ChevronIcon />
        Back
      </Button>
    </Wrapper>
  );
}
