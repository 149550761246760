// General
import { FC, SVGProps } from 'react';

const TwitterIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.9622 4H20.877L14.5106 11.2005L22 21H16.1373L11.5422 15.0582L6.29053 21H3.37158L10.1797 13.2969L3 4H9.0113L13.1606 9.43101L17.9622 4ZM16.9383 19.2755H18.5526L8.1319 5.63462H6.39787L16.9383 19.2755Z"
      fill="currentColor" />
  </svg>

);

export default TwitterIcon;
