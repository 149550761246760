import styled from 'styled-components';
import { useEffect, useRef } from 'react';

const StyledDiv = styled.div`
  align-self: center;
  transform: scale(0.8);
`;

export default function TrustPilotBox() {
  const trustPilotRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if ((window as any)?.Trustpilot) {
      (window as any).Trustpilot.loadFromElement(trustPilotRef.current, true);
    }
  }, []);

  return (
    <StyledDiv
      ref={trustPilotRef}
      className='trustpilot-widget'
      data-locale='en-GB'
      data-style-height='130px'
      data-template-id='53aa8807dec7e10d38f59f32'
      data-businessunit-id='66ac7ebaa28d00325aaf0bc0'
      data-theme='dark'>
      <a href='https://uk.trustpilot.com/review/mooncourt.xyz ' target='_blank' rel='noopener'>
        Trustpilot
      </a>
    </StyledDiv>
  );
}
