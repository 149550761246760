import { useMemo } from 'react';
import styled from 'styled-components';
import { ILink } from '../../types/link';
import OpenSeaIcon from '../icons/OpenSeaIcon';
import TwitterIcon from '../icons/TwitterIcon';
import DiscordIcon from '../icons/DiscordIcon';
import EmailIcon from '../icons/EmailIcon';
import LinkedInIcon from '../icons/LinkedInIcon';
import { Colors } from '../../styles/Colors';
import Fortnite from '../icons/Fortnite';
import YoutubeIcon from '../icons/YoutubeIcon';
import TwitchIcon from '../icons/TwitchIcon';

const SocialLink = styled.a`
  pointer-events: auto;
  width: var(--size, 2.75rem);
  height: var(--size, 2.75rem);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  &:disabled {
    border: 3px solid ${Colors.textDefault};
    background: transparent;
    color: ${Colors.textDefault};
    filter: none;
    box-shadow: none;
    opacity: 0.3;
    pointer-events: none;

    &::before {
      opacity: 0.5;
    }
  }

  @media (hover: hover) {
    :hover {
      background: none;
      color: var(--color-text-default);
      box-shadow: none;

      svg {
        color: var(--color-primary-750);
      }
    }
  }
`;

export interface IIconLinkProps {
  icon: string; // 'opensea' | 'twitter' | 'discord' | 'linkedIn' | 'email' | 'fortnite';
  link: ILink;
  hasBorder?: boolean;
  className?: string;
}

export default function IconLink(props: IIconLinkProps) {
  const { icon, link, className } = props;

  const selectedIcon = useMemo(() => {
    switch (icon) {
      case 'opensea':
        return <OpenSeaIcon />;
      case 'twitter':
        return <TwitterIcon />;
      case 'discord':
        return <DiscordIcon />;
      case 'linkedIn':
        return <LinkedInIcon />;
      case 'email':
        return <EmailIcon />;
      case 'fortnite':
        return <Fortnite />;
      case 'youtube':
        return <YoutubeIcon />;
      case 'twitch':
        return <TwitchIcon />;
    }
  }, [icon]);

  return (
    <SocialLink {...link} theme={'secondary'} className={className}>
      {selectedIcon}
    </SocialLink>
  );
}
