import styled from 'styled-components';
import { Button } from '../../../common/Button';
import { FontFamilies } from '../../../../styles/FontFamilies';
import { BREAKPOINT_MD } from '../../../../styles/Breakpoints';
import { Glass } from '../../../../styles/Custom';

const Wrapper = styled.div`
  ${Glass};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.5rem 0.75rem;
  gap: 1.5rem;
  justify-content: center;

  ${BREAKPOINT_MD} {
    width: 12rem;
  }
`;

const Text = styled.h4`
  font-family: ${FontFamilies.headline};
  font-size: 1.75rem;
  line-height: 1.25;
  font-weight: 600;
  text-transform: uppercase;
`;

const AddButton = styled(Button)`
  display: flex;
  gap: 4px;
  width: 8rem;
  align-items: center;
  justify-content: center;

  span {
    font-size: 1.5rem;
    font-weight: 600;
  }
`;

interface IAddWalletCardProps {
  onClick: () => void;
}

export default function AddWalletCard(props: IAddWalletCardProps) {
  const { onClick } = props;

  return (
    <Wrapper>
      <Text>add new wallet</Text>

      <AddButton theme={'primary'} onClick={onClick}>
        <span>+</span>
        Add
      </AddButton>
    </Wrapper>
  );
}
