import Modal from '../../../common/Modal';
import styled from 'styled-components';
import { Button } from '../../../common/Button';
import { useAccount } from 'wagmi';
import { Translations } from '../../../../utils/Translations';

const Wrapper = styled.div``;

const Headline = styled.h3`
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-block-end: 1rem;
`;

const Text = styled.p`
  margin-block-end: 0.25rem;
`;

const Wallet = styled.p`
  color: var(--color-grey-650);
  word-break: break-word;
  margin-block-end: 2.5rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

interface IConfirmDeleteWalletModalProps {
  onClick: () => void;
  onClose: () => void;
}

export default function ConfirmDeleteWalletModal(props: IConfirmDeleteWalletModalProps) {
  const { onClick, onClose } = props;
  const { address } = useAccount();

  return (
    <Modal close={onClose}>
      <Wrapper>
        <Headline>{Translations.walletConnect.deleteHeadline}</Headline>

        <Text>{Translations.walletConnect.deleteText}</Text>
        <Wallet>Wallet: {address}</Wallet>

        <ButtonWrapper>
          <Button theme={'secondary'} onClick={onClose}>
            Cancel
          </Button>

          <Button theme={'primary'} onClick={onClick}>
            Delete
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </Modal>
  );
}
