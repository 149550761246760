import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Translations } from 'src/utils/Translations';
import { Button } from 'src/components/common/Button';
import AnimatedAccordion from 'src/components/common/AnimatedAccordion';
import { useRecoilValue } from 'recoil';
import { AccountState } from 'src/states/AppData';
import Wysiwyg from 'src/components/common/Wysiwyg';
import { useAccountApi } from 'src/services/account';
import useToast from 'src/hooks/useToast';
import { Notifications } from 'src/components/layout/app/Layout';
import BallIcon from 'src/components/icons/BallIcon';
import Checkbox from 'src/components/common/form/Checkbox';
import { Form } from 'src/components/common/form/Form';
import { useForm } from 'react-hook-form';
import LinkHandler from 'src/components/common/LinkHandler';

const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Headline = styled.h2`
  font-size: var(--font-size-headline-md);
  line-height: 1;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin-block-end: 2rem;
`;

const Text = styled(Wysiwyg)`
  gap: 0;
  font-size: var(--font-size-body-md);
  line-height: 1.25;
  margin-block-end: 2rem;

  span {
    font-weight: 600;
    margin-block: 0.5rem;
  }
`;

const InfoButton = styled.button`
  font-size: var(--font-size-body-sm);
  text-decoration: underline;
  margin-block-end: 0.75rem;
  font-weight: 600;

  &:hover {
    color: var(--color-primary-750);
  }
`;

const BenefitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-block-end: 2rem;
`;

const Benefit = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;

  svg {
    flex-shrink: 0;
    font-size: 1.5rem;
  }
`;

const BenefitText = styled.p`
  font-size: var(--font-size-body-md);
  font-weight: 600;
  line-height: 1.25;
`;

const ConfirmationText = styled.p`
  text-align: center;
  font-size: var(--font-size-body-md);
  line-height: 1.25;
`;

const Email = styled(ConfirmationText)`
  font-weight: 600;
  margin-block-end: 1.25rem;
`;

const StyledButton = styled(Button)`
  width: 100%;
  justify-content: center;
  margin-block-end: 1.25rem;
`;

const StyledCheckbox = styled(Checkbox)`
  font-size: var(--font-size-body-sm);
  line-height: 1.25;
  margin-block-end: 1.25rem;

  label {
    gap: 1.25rem;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledLinkHandler = styled(LinkHandler)`
  font-size: var(--font-size-body-md);
  font-weight: 600;
  text-decoration: underline;

  &:hover {
    color: var(--color-primary-750);
  }
`;

interface INewsletterModalProps {
  onClose: () => void;
}

export default function NewsletterModalBody(props: INewsletterModalProps) {
  const { onClose } = props;
  const accountState = useRecoilValue(AccountState);
  const accountApi = useAccountApi();
  const { addErrorToast } = useToast();
  const notifications = useContext(Notifications);

  const form = useForm();
  const newsletter = form.watch('newsletter');

  const [openInfoBanner, setOpenInfoBanner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<0 | 1>(0);

  async function triggerOptInMail() {
    setLoading(true);
    try {
      const response = await accountApi.newsletterSignUp();
      if (response.success) {
        setStep(1);
      } else {
        addErrorToast(notifications!.internalServerError);
      }
    } catch (error) {
      console.log('error signing up to newsletter:', error);
      addErrorToast(notifications!.internalServerError);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Wrapper form={form} onSubmit={triggerOptInMail}>
      <AnimatedAccordion open={step === 0}>
        <Headline>{Translations.newsletter.modal.headline.initial}</Headline>
        <Text content={Translations.newsletter.modal.text} />
        <BenefitsWrapper>
          {Translations.newsletter.modal.benefits.map((item, index) => (
            <Benefit key={index}>
              <BallIcon />
              <BenefitText>{item.text}</BenefitText>
            </Benefit>
          ))}
        </BenefitsWrapper>
        <StyledCheckbox name={'newsletter'}>{Translations.newsletter.modal.checkboxLabel}</StyledCheckbox>
        <StyledButton theme={'primary'} onClick={triggerOptInMail} disabled={loading || !newsletter}>
          {Translations.newsletter.modal.buttonLabel}
        </StyledButton>
        <LinkWrapper>
          <StyledLinkHandler to={Translations.newsletter.modal.link.href} target={'_blank'}>
            {Translations.newsletter.modal.link.title}
          </StyledLinkHandler>
        </LinkWrapper>
      </AnimatedAccordion>

      <AnimatedAccordion open={step === 1}>
        <Headline>{Translations.newsletter.modal.headline.confirmation}</Headline>
        <ConfirmationText>{Translations.newsletter.modal.confirmationText.start}</ConfirmationText>
        <Email>{accountState?.account.email || ''}</Email>
        <Text content={Translations.newsletter.modal.confirmationText.end} />
        <InfoButton type={'button'} onClick={() => setOpenInfoBanner(!openInfoBanner)}>
          {Translations.newsletter.modal.confirmationText.info.buttonLabel}
        </InfoButton>
        <AnimatedAccordion open={openInfoBanner}>
          <Text content={Translations.newsletter.modal.confirmationText.info.text} />
        </AnimatedAccordion>
        <StyledButton type={'button'} onClick={onClose}>
          {Translations.newsletter.modal.confirmationText.buttonLabel}
        </StyledButton>
      </AnimatedAccordion>
    </Wrapper>
  );
}
