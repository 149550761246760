import { SVGProps } from 'react';

export default function PassiveIndicatorIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 3.875C14.1549 3.875 16.2215 4.73102 17.7452 6.25476C19.269 7.77849 20.125 9.84512 20.125 12C20.125 14.1549 19.269 16.2215 17.7452 17.7452C16.2215 19.269 14.1549 20.125 12 20.125C9.84512 20.125 7.77849 19.269 6.25476 17.7452C4.73102 16.2215 3.875 14.1549 3.875 12C3.875 9.84512 4.73102 7.77849 6.25476 6.25476C7.77849 4.73102 9.84512 3.875 12 3.875ZM12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34784 22 12 22ZM15.8242 9.5L14.5 8.17578L13.8359 8.83984L12 10.6758L10.1641 8.83984L9.5 8.17578L8.17578 9.5L8.83984 10.1641L10.6758 12L8.83984 13.8359L8.17578 14.5L9.5 15.8242L10.1641 15.1602L12 13.3242L13.8359 15.1602L14.5 15.8242L15.8242 14.5L15.1602 13.8359L13.3242 12L15.1602 10.1641L15.8242 9.5Z'
        fill='currentColor'
      />
    </svg>
  );
}
