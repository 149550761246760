export const Countries = [
  { value: 'Egypt', name: 'Egypt' },
  { value: 'Mauritius', name: 'Mauritius' },
  { value: 'Azerbaijan', name: 'Azerbaijan' },
  { value: 'Bahrain', name: 'Bahrain' },
  { value: 'China', name: 'China' },
  { value: 'Hongkong', name: 'Hongkong' },
  { value: 'India', name: 'India' },
  { value: 'Indonesia', name: 'Indonesia' },
  { value: 'Israel', name: 'Israel' },
  { value: 'Japan', name: 'Japan' },
  { value: 'Republic of Korean', name: 'Republic of Korean' },
  { value: 'Kuwait', name: 'Kuwait' },
  { value: 'Mongolia', name: 'Mongolia' },
  { value: 'Philippines', name: 'Philippines' },
  { value: 'Saudi Arabia', name: 'Saudi Arabia' },
  { value: 'Singapore', name: 'Singapore' },
  { value: 'Taiwan', name: 'Taiwan' },
  { value: 'Thailand', name: 'Thailand' },
  { value: 'United Arab Emirates', name: 'United Arab Emirates' },
  { value: 'Andorra', name: 'Andorra' },
  { value: 'Austria', name: 'Austria' },
  { value: 'Belgium', name: 'Belgium' },
  { value: 'Bosnia and Herzegovina', name: 'Bosnia and Herzegovina' },
  { value: 'Bulgaria', name: 'Bulgaria' },
  { value: 'Croatia', name: 'Croatia' },
  { value: 'Cyprus', name: 'Cyprus' },
  { value: 'Czech Republic', name: 'Czech Republic' },
  { value: 'Denmark', name: 'Denmark' },
  { value: 'Estonia', name: 'Estonia' },
  { value: 'Finland', name: 'Finland' },
  { value: 'France', name: 'France' },
  { value: 'Germany', name: 'Germany' },
  { value: 'Greece', name: 'Greece' },
  { value: 'Hungary', name: 'Hungary' },
  { value: 'Iceland', name: 'Iceland' },
  { value: 'Ireland', name: 'Ireland' },
  { value: 'Italy', name: 'Italy' },
  { value: 'Latvia', name: 'Latvia' },
  { value: 'Liechtenstein', name: 'Liechtenstein' },
  { value: 'Lithuania', name: 'Lithuania' },
  { value: 'Luxembourg', name: 'Luxembourg' },
  { value: 'Macedonia', name: 'Macedonia' },
  { value: 'Malta', name: 'Malta' },
  { value: 'Monaco', name: 'Monaco' },
  { value: 'Montenegro', name: 'Montenegro' },
  { value: 'Netherlands', name: 'Netherlands' },
  { value: 'Norway', name: 'Norway' },
  { value: 'Poland', name: 'Poland' },
  { value: 'Portugal', name: 'Portugal' },
  { value: 'Romania', name: 'Romania' },
  { value: 'San Marino', name: 'San Marino' },
  { value: 'Serbia', name: 'Serbia' },
  { value: 'Slovakia', name: 'Slovakia' },
  { value: 'Slovenia', name: 'Slovenia' },
  { value: 'Spain', name: 'Spain' },
  { value: 'Sweden', name: 'Sweden' },
  { value: 'Switzerland', name: 'Switzerland' },
  { value: 'Turkey', name: 'Turkey' },
  { value: 'United Kingdom', name: 'United Kingdom' },
  { value: 'Antigua', name: 'Antigua' },
  { value: 'Barbuda', name: 'Barbuda' },
  { value: 'Bahamas', name: 'Bahamas' },
  { value: 'Barbados', name: 'Barbados' },
  { value: 'Canada', name: 'Canada' },
  { value: 'Costa Rica', name: 'Costa Rica' },
  { value: 'Cuba', name: 'Cuba' },
  { value: 'Dominican Republic', name: 'Dominican Republic' },
  { value: 'Guatemala', name: 'Guatemala' },
  { value: 'Mexico', name: 'Mexico' },
  { value: 'Puerto Rico', name: 'Puerto Rico' },
  { value: 'Saint Lucia', name: 'Saint Lucia' },
  { value: 'United States of America', name: 'United States of America' },
  { value: 'Australia', name: 'Australia' },
  { value: 'New Zealand', name: 'New Zealand' },
  { value: 'Argentina', name: 'Argentina' },
  { value: 'Brazil', name: 'Brazil' },
  { value: 'Ecuador', name: 'Ecuador' },
  { value: 'Netherlands Antilles', name: 'Netherlands Antilles' },
  { value: 'Peru', name: 'Peru' },
  { value: 'Trinidad and Tobago', name: 'Trinidad and Tobago' },
  { value: 'Venezuela', name: 'Venezuela' },
];
