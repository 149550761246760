import styled, { css } from 'styled-components';
import ChevronIcon from '../icons/ChevronIcon';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
`;

const Button = styled.button`
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1rem;
  color: var(--color-text-default);

  &:disabled {
    color: var(--color-grey-650);
    pointer-events: none;
  }

  path {
    stroke-width: 2px;
  }

  &:first-child {
    transform: rotate(90deg);
  }

  &:last-child {
    transform: rotate(-90deg);
  }

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary-750);
    }
  }
`;

const Dot = styled.span<{ active: boolean }>`
  border-radius: 100%;
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--color-grey-650);

  ${({ active }) =>
    active &&
    css`
      background-color: var(--color-text-default);
      filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.7)) drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.8));
    `}
`;

interface IPaginationProps {
  currentIndex: number;
  changeIndex: (index: number) => void;
  amount: number;
  showArrows?: boolean;
  className?: string;
}

export default function Pagination(props: IPaginationProps) {
  const { currentIndex, changeIndex, amount, showArrows = true, className } = props;

  return (
    <Wrapper className={className}>
      {showArrows && (
        <Button onClick={() => changeIndex(currentIndex - 1)} disabled={currentIndex === 0}>
          <ChevronIcon />
        </Button>
      )}

      {Array(amount)
        .fill('')
        .map((_, index) => (
          <Dot key={index} active={index === currentIndex} />
        ))}

      {showArrows && (
        <Button onClick={() => changeIndex(currentIndex + 1)} disabled={currentIndex === amount - 1}>
          <ChevronIcon />
        </Button>
      )}
    </Wrapper>
  );
}
