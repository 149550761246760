import { useApi } from './ApiProvider';

export const useSSOApi = () => {
  const apiInstance = useApi();

  const googleLogin = async (token: string) => {
    return (await apiInstance.post(`/v2/account/google/validate`, { token }, { validateStatus: () => true })).data;
  };

  const appleLogin = async (token: string) => {
    return (await apiInstance.post(`/v2/account/apple/validate`, { token })).data;
  };

  const discordLogin = async (token: string) => {
    return (await apiInstance.post(`/v2/account/discord/validate`, { token })).data;
  };

  return {
    googleLogin,
    appleLogin,
    discordLogin,
  };
};
