import styled from 'styled-components';
import { FontFamilies } from '../../../../styles/FontFamilies';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL, BREAKPOINT_XXL } from '../../../../styles/Breakpoints';
import { Form } from '../../../common/form/Form';
import LoadingAnimation from '../../../common/LoadingAnimation';

export const DetailPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex-grow: 1;
`;

export const DetailPageTitle = styled.h1`
  font-size: 2.75rem;
  line-height: 1.1;
  margin-block-end: 0.5rem;
  text-transform: uppercase;
`;

export const DetailPageSubHeadline = styled.h3`
  font-family: ${FontFamilies.headline};
  font-size: 1.75rem;
  font-weight: 700;
  text-transform: uppercase;
`;

export const DetailPageContent = styled(Form)`
  display: flex;
  flex-direction: column;
  height: min-content;

  ${BREAKPOINT_MD} {
    position: sticky;
    top: 1.5rem;
    grid-column: 2;
  }

  ${BREAKPOINT_XL} {
    top: calc(var(--nav-height) + 1.5rem);
  }

  ${BREAKPOINT_XXL} {
    grid-column: 14 / -1;
  }
`;

export const DetailPageContainer = styled.div`
  display: grid;
  column-gap: 1.5rem;
  grid-column: 1 / -1;
  margin-block-end: 3.75rem;

  ${BREAKPOINT_MD} {
    margin-block-end: 6.25rem;
    grid-template-columns: repeat(2, 1fr);
  }

  ${BREAKPOINT_LG} {
    column-gap: 2.5rem;
  }

  ${BREAKPOINT_XXL} {
    grid-template-columns: repeat(20, 1fr);
    column-gap: 1.25rem;
  }
`;

export const DetailPageLoadingAnimation = styled(LoadingAnimation)`
  height: 100%;
  font-size: 3rem;
  align-self: center;
  margin-block: auto;

  ${BREAKPOINT_MD} {
    font-size: 5rem;
  }
`;

export const DetailPagePurchaseInfo = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem;
  background: var(--color-text-highlight);
  border: 1px solid var(--color-text-default);
  border-radius: 0.375rem;
  font-size: 0.875rem;

  svg {
    font-size: 1.5rem;
  }
`;

export const DetailPageInfoWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-block-end: 2.5rem;
`;

export const DetailPageTag = styled.div`
  align-self: flex-start;
  display: flex;
  justify-self: flex-start;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  border-radius: 4px;
  background: var(--color-signal-red);
  backdrop-filter: blur(4px);
  font-size: 0.875rem;
  line-height: 1;
  font-family: ${FontFamilies.headline};
  gap: 1.5rem;
  text-transform: uppercase;
  color: var(--color-text-highlight);
  letter-spacing: 1px;
`;
