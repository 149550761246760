import { FC, SVGProps } from 'react';

export const BallIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props} viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.4688 20.8016C14.4688 20.7687 14.4688 20.7359 14.4688 20.7031C14.4688 19.3373 14.9076 18.074 15.6541 17.0445L17.7951 19.1855C16.8313 19.9484 15.7033 20.5062 14.4688 20.8016ZM12.5082 21.0312C12.5041 21.0312 12.5041 21.0312 12.5 21.0312C10.4984 21.0312 8.65684 20.3422 7.20488 19.1896L12.5 13.8904L14.2473 15.6377C13.1521 17.0363 12.5 18.7918 12.5 20.7031C12.5 20.8139 12.5041 20.9205 12.5082 21.0312ZM15.6418 14.2473L13.8904 12.5L19.1896 7.20488C20.3422 8.65684 21.0312 10.4984 21.0312 12.5C21.0312 12.5041 21.0312 12.5041 21.0312 12.5082C20.9205 12.5041 20.8139 12.5 20.7031 12.5C18.7918 12.5 17.0363 13.1521 15.6418 14.2473ZM20.8016 14.4688C20.5104 15.7033 19.9484 16.8354 19.1855 17.7951L17.0445 15.6541C18.0699 14.9076 19.3373 14.4688 20.7031 14.4688C20.7359 14.4688 20.7687 14.4688 20.8016 14.4688ZM12.5 11.1096L10.7527 9.3623C11.8479 7.96367 12.5 6.2082 12.5 4.29688C12.5 4.18613 12.4959 4.07949 12.4918 3.96875C12.4959 3.96875 12.4959 3.96875 12.5 3.96875C14.5016 3.96875 16.3432 4.65781 17.7951 5.81035L12.5 11.1096ZM9.3459 7.95547L7.20488 5.81035C8.16875 5.04746 9.29668 4.48965 10.5312 4.19434C10.5312 4.22715 10.5312 4.25996 10.5312 4.29277C10.5312 5.65859 10.0924 6.92188 9.3459 7.95137V7.95547ZM7.95547 9.3459C6.92598 10.0924 5.6627 10.5312 4.29688 10.5312C4.26406 10.5312 4.23125 10.5312 4.19844 10.5312C4.48965 9.29668 5.05156 8.16465 5.81445 7.20488L7.95547 9.3459ZM3.96875 12.4918C4.07949 12.4959 4.18613 12.5 4.29688 12.5C6.2082 12.5 7.96367 11.8479 9.3582 10.7527L11.1096 12.5L5.81035 17.7951C4.65781 16.3432 3.96875 14.5016 3.96875 12.5C3.96875 12.4959 3.96875 12.4959 3.96875 12.4918ZM12.5 23C15.2848 23 17.9555 21.8938 19.9246 19.9246C21.8938 17.9555 23 15.2848 23 12.5C23 9.71523 21.8938 7.04451 19.9246 5.07538C17.9555 3.10625 15.2848 2 12.5 2C9.71523 2 7.04451 3.10625 5.07538 5.07538C3.10625 7.04451 2 9.71523 2 12.5C2 15.2848 3.10625 17.9555 5.07538 19.9246C7.04451 21.8938 9.71523 23 12.5 23Z'
      fill='currentColor'
    />
  </svg>
);

export default BallIcon;
