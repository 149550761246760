// General
import { SVGProps } from 'react';

export default function PlusIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.15385 14.3462V15.5H6.84615V14.3462V9.15385H1.65385H0.5V6.84615H1.65385H6.84615V1.65385V0.5H9.15385V1.65385V6.84615H14.3462H15.5V9.15385H14.3462H9.15385V14.3462Z'
        fill='currentColor'
      />
    </svg>
  );
}
