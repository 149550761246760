import styled from 'styled-components';
import { Translations } from 'src/utils/Translations';
import { Form } from 'src/components/common/form/Form';
import { useForm } from 'react-hook-form';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import TextInput from 'src/components/common/form/TextInput';
import { Button } from 'src/components/common/Button';
import UserImage from 'src/components/common/UserImage';
import { useRecoilValue } from 'recoil';
import { AccountState } from 'src/states/AppData';
import { useUserApi } from 'src/services/user';

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledUserImage = styled(UserImage)`
  margin-block-end: 2rem;
`;

const Headline = styled.h4`
  font-size: var(--font-size-headline-md);
  font-weight: 600;
  line-height: 1;
  margin-block-end: 2rem;
  text-transform: uppercase;
`;

const InputLabel = styled.label`
  font-size: var(--font-size-body-md);
  font-weight: 600;
  margin-block-end: 0.75rem;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-block-end: 2rem;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  justify-content: center;
  width: 100%;
`;

const FileInput = styled.input`
  display: none;
  visibility: none;
`;

interface IUserProfileFormProps {
  username: string;
  image?: File;
}

interface IUserProfileModalProps {
  onClose: () => void;
}

interface IUserImage {
  preview?: string;
  file?: File;
}

export default function UserProfileModalBody(props: IUserProfileModalProps) {
  const { onClose } = props;

  const { updateAccountState } = useUserApi();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const accountState = useRecoilValue(AccountState);

  const form = useForm<IUserProfileFormProps>();
  const username = form.watch('username');

  const [imageState, setImageState] = useState<IUserImage>({ preview: accountState?.account.image?.sizes.thumbnail.url });
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function submitHandler(formData: IUserProfileFormProps) {
    setIsLoading(true);
    try {
      let data = new FormData();
      imageState?.file && data.append('image', imageState.file);
      accountState?.account.username !== formData.username && data.append('username', formData.username);
      const response = await updateAccountState(data);
      if (response?.code === 200) {
        onClose();
      } else if (response?.message === 'USERNAME_ALREADY_TAKEN') {
        form.setError('username', { message: 'Username already taken' });
        setIsFormValid(false);
      }
    } catch (error) {
      console.log('[ERROR] updating user profile:', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (accountState?.account.username !== username) {
      setIsFormValid(true);
    } else if (imageState?.file && accountState?.account.image?.sizes.thumbnail.url !== imageState.preview) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [accountState, username, imageState]);

  function openImageInput() {
    fileInputRef.current?.click();
  }

  async function handleImageInputChange(value: ChangeEvent<HTMLInputElement>) {
    setImageState({ preview: URL.createObjectURL(value.target.files![0]), file: value.target.files?.[0] });
  }

  return (
    <Form form={form} onSubmit={submitHandler}>
      <InnerWrapper>
        <Headline>{Translations.userProfileModal.headline}</Headline>
        <StyledUserImage
          size={110}
          image={imageState.preview}
          username={accountState?.account?.username}
          onClick={openImageInput}
          hasEditIcon
        />
        <InputLabel>{Translations.userProfileModal.usernameInputLabel}</InputLabel>
        <InputWrapper>
          <StyledTextInput
            type='text'
            placeholder='Your Username'
            name='username'
            title='username'
            defaultValue={accountState?.account?.username}
          />
        </InputWrapper>
        <StyledButton disabled={!isFormValid || isLoading}>{Translations.userProfileModal.buttonLabel}</StyledButton>
        <FileInput
          type={'file'}
          name={'image'}
          accept={'image/png, image/jpeg, image/webp'}
          ref={fileInputRef}
          onChange={handleImageInputChange}
        />
      </InnerWrapper>
    </Form>
  );
}
