import { useApi } from './ApiProvider';
import { IAccount } from '@m3ta-collective/mooncourt-utilities-library';
import { IAddress, IUserAddress } from '../types/address';
import { useSetRecoilState } from 'recoil';
import { AccountState, AddressState, IConnectedWallet, WalletState } from '../states/AppData';
import { AxiosResponse } from 'axios';

interface IWalletRequestProps {
  wallet: string;
  walletAuthorization: string;
}

interface IUpdateAddressProps {
  billingEqualsShipping: boolean;
  billing: IAddress;
  shipping: IAddress;
}

interface IConnectWalletResponse {
  wallets: IConnectedWallet[];
  pointsAdded: number;
  level: number;
}

interface IDisconnectWalletResponse {
  wallets: IConnectedWallet[];
}

const BASE_URL = '/v2/account/user';

export const useUserApi = () => {
  const apiInstance = useApi();
  const setAccountState = useSetRecoilState(AccountState);
  const setWalletState = useSetRecoilState(WalletState);
  const setAddressState = useSetRecoilState(AddressState);

  const getUser = async () => {
    return (await apiInstance.get<IAccount>(`${BASE_URL}`)).data;
  };

  const refreshAccountState = async () => {
    try {
      const accountResponse = (await apiInstance.get(BASE_URL)).data;
      setAccountState(accountResponse);
    } catch (error) {
      console.log('error refreshing user account:', error);
    }
  };

  const getStats = async () => {
    return (await apiInstance.get(`${BASE_URL}/stats`)).data;
  };

  const getWallets = async () => {
    return (await apiInstance.get(`${BASE_URL}/wallets`)).data;
  };

  const refreshWalletState = async () => {
    try {
      const walletsResponse = await apiInstance.get(`${BASE_URL}/wallets`);
      setWalletState(walletsResponse.data);
    } catch (error) {
      console.log('error refreshing user wallets:', error);
    }
  };

  const connectWallet = async ({ wallet, walletAuthorization }: IWalletRequestProps) => {
    return (
      await apiInstance.post<IConnectWalletResponse>(
        `${BASE_URL}/wallets/connect`,
        { wallet },
        {
          headers: {
            'wallet-authorization': walletAuthorization,
          },
        },
      )
    ).data;
  };

  const disconnectWallet = async ({ wallet, walletAuthorization }: IWalletRequestProps) => {
    return (
      await apiInstance.post<IDisconnectWalletResponse>(
        `${BASE_URL}/wallets/disconnect`,
        { wallet },
        {
          headers: {
            'wallet-authorization': walletAuthorization,
          },
        },
      )
    ).data;
  };

  const getAddress = async () => {
    return (await apiInstance.get<IUserAddress>(`${BASE_URL}/address`)).data;
  };

  const updateAddress = async (data: IUpdateAddressProps) => {
    return (await apiInstance.patch<IUserAddress>(`${BASE_URL}/address`, data)).data;
  };

  const refreshAddressState = async () => {
    try {
      const addressResponse = await apiInstance.get(`${BASE_URL}/address`);
      addressResponse?.data && setAddressState(addressResponse.data);
    } catch (error: any) {
      console.log('error refreshing user address:', error?.code, error?.message);
    }
  };

  // TODO @daniel fix nonce generation / verification

  const getNonce = async () => {
    return (await apiInstance.get<{ message: string; nonce: string }>(`${BASE_URL}/wallets/connect/nonce`)).data;
  };

  const verifyNonce = async (walletAddress: string, signedMessage: string, nonce: string) => {
    return (
      await apiInstance.post<{ authToken: string; walletAddress: string }>(
        `${BASE_URL}/wallets/connect/nonce`,
        JSON.stringify({
          walletAddress,
          signedMessage,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            Nonce: nonce,
          },
        },
      )
    ).data;
  };

  const updateAccountState = async (formData: any) => {
    try {
      const response = (await apiInstance.post(`${BASE_URL}`, formData)).data;
      setAccountState(response);
      return { code: 200, message: 'User updated successfully' };
    } catch (error) {
      console.log('[error] updating user account:', error);
      return { code: (error as any)?.status, message: (error as any)?.response?.data?.message };
    }
  };

  return {
    getUser,
    refreshAccountState,
    updateAccountState,
    getStats,
    getWallets,
    refreshWalletState,
    connectWallet,
    disconnectWallet,
    getAddress,
    updateAddress,
    refreshAddressState,
    getNonce,
    verifyNonce,
  };
};
