import styled from 'styled-components';
import Modal from 'src/components/common/Modal';
import Mooncourt from 'src/components/icons/Mooncourt';
import { useState } from 'react';
import AnimatedAccordion from 'src/components/common/AnimatedAccordion';
import NewsletterModalBody from 'src/components/content/newsletter/NewsletterModalBody';
import UserProfileModalBody from 'src/components/content/profile/UserProfileModalBody';
import { useRecoilValue } from 'recoil';
import { AccountState } from 'src/states/AppData';
import { BREAKPOINT_MD } from 'src/styles/Breakpoints';
import { useAccountApi } from 'src/services/account';

const StyledModal = styled(Modal)`
  background: var(--color-text-highlight);
  max-width: 32rem;
  padding: 3rem 2rem;

  ${BREAKPOINT_MD} {
    padding: 3rem 4.375rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled(Mooncourt)`
  font-size: 1.5rem;
  color: var(--color-primary-750);
  margin-block-end: 0.5rem;
`;

interface IOnBoardingModalProps {
  onClose: () => void;
  initialStep?: 'username' | 'newsletter';
}

export default function OnBoardingModal(props: IOnBoardingModalProps) {
  const { onClose, initialStep = 'username' } = props;
  const accountState = useRecoilValue(AccountState);
  const [step, setStep] = useState<'username' | 'newsletter'>(initialStep);
  const { hideNewsletterModal } = useAccountApi();

  function handleUserProfileOnClose() {
    if (accountState?.newsletter?.popUpShown) {
      onClose();
    } else {
      setStep('newsletter');
    }
  }

  function handleModalOnClose() {
    if (step === 'newsletter' && accountState?.newsletter?.popUpShown === false) {
      hideNewsletterModal();
    }
    onClose();
  }

  return (
    <StyledModal close={step === 'username' && !accountState?.account.username ? undefined : handleModalOnClose}>
      <Wrapper>
        <Logo />
        <AnimatedAccordion open={step === 'username'}>
          <UserProfileModalBody onClose={handleUserProfileOnClose} />
        </AnimatedAccordion>

        <AnimatedAccordion open={step === 'newsletter'}>
          <NewsletterModalBody onClose={onClose} />
        </AnimatedAccordion>
      </Wrapper>
    </StyledModal>
  );
}
