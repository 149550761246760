import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import RefreshIcon from '../../icons/RefreshIcon';
import { BREAKPOINT_MD, DIMEN_BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { ShopItemCategory, ShopItemProps, RaffleProps, useShopApi } from '../../../services/shop';
import { Translations } from '../../../utils/Translations';
import { InternalStyledButton, RefreshButton, Wrapper } from '../Container';
import InternalContentGrid from '../InternalContentGrid';
import ShopItemCard from '../cards/ShopItemCard';
import RaffleTicketCard from '../cards/RaffleTicketCard';
import ComingSoonCard, { IComingSoonItem } from '../cards/ComingSoonCard';

const HeaderWrapper = styled.div`
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column-reverse;
    gap: 2rem;

    ${BREAKPOINT_MD} {
        justify-content: space-between;
        flex-direction: row;
    }
`;

const ControlsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    align-items: center;
`;

const LevelButton = styled(InternalStyledButton)<{ active: boolean }>`
    ${({ active }) =>
            active &&
            css`
                border-color: var(--color-primary-750);
                color: var(--color-primary-750);
                pointer-events: none;
            `};
`;

export const Shop: FC = () => {
  const [shopItems, setShopItems] = useState<ShopItemProps[] | null>(null);
  const [raffles, setRaffles] = useState<RaffleProps[] | null>(null);
  const [comingSoonItems, setComingSoonItems] = useState<IComingSoonItem[] | null>(null);
  const [categories, setCategories] = useState<ShopItemCategory[] | null>(null);
  const [currentCategory, setCurrentCategory] = useState<ShopItemCategory | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const shopApi = useShopApi();

  const sortedItems = useMemo(() => {
    const allItems = [
      ...(shopItems || []).map((item) => ({ ...item, type: 'shop' })),
      ...(raffles || []).map((item) => ({ ...item, type: 'raffle' })),
    ];

    const endInItems = allItems
      .filter(({ startDate, endDate }) => {
        if (startDate && new Date(startDate).getTime() > new Date().getTime()) {
          return false;
        } else {
          return endDate && new Date(endDate).getTime() > new Date().getTime();
        }
      })
      .sort((a, b) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime());

    const itemsWithoutDate = allItems.filter(
      ({ startDate, endDate }) => !endDate && (!startDate || new Date(startDate).getTime() < new Date().getTime()),
    );

    const startInItems = allItems
      .filter(({ startDate, endDate }) => {
        if (endDate && new Date(endDate).getTime() < new Date().getTime()) {
          return false;
        } else return startDate && new Date(startDate).getTime() > new Date().getTime();
      })
      .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

    const comingSoon = (comingSoonItems || [])
      .map((item) => ({ ...item, type: 'comingSoon' }))
      .sort((a, b) => new Date(a.reveal).getTime() - new Date(b.reveal).getTime());

    const endedItems = allItems
      .filter(({ endDate }) => endDate && new Date(endDate).getTime() < new Date().getTime())
      .sort((a, b) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime() ? 1 : -1);

    return [...endInItems, ...itemsWithoutDate, ...startInItems, ...comingSoon, ...endedItems];
  }, [shopItems, raffles, comingSoonItems]);

  const changeView = ({ matches }: MediaQueryListEvent) => {
    setIsMobile(matches);
  };

  useEffect(() => {
    const view = window.matchMedia(`(max-width: ${DIMEN_BREAKPOINT_MD}px)`);
    setIsMobile(view.matches);
    view.addEventListener('change', changeView);

    return () => {
      view.removeEventListener('change', changeView);
    };
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const [items, categories, raffles] = await Promise.all([
        shopApi.getShopItems(),
        shopApi.getShopItemCategories(),
        shopApi.getRaffles(),
      ]);

      setShopItems(items.filter(({ comingSoon }: { comingSoon?: boolean }) => !comingSoon));
      setRaffles(raffles.filter(({ item }: { item?: any }) => !item?.comingSoon));
      setComingSoonItems([
        ...(items || []).filter(({ comingSoon }: any) => comingSoon),
        ...(raffles || []).filter(({ item }: any) => item?.comingSoon).map(({ item }: any) => item),
      ]);
      setCategories(((categories as ShopItemCategory[]) || [])?.sort((a, b) => (a?.label > b?.label ? 1 : -1)));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setCurrentCategory(null);
    }
  }, []);

  const fetchAllItems = async () => {
    setLoading(true);
    try {
      const items = await shopApi.getShopItems();
      const raffles = await shopApi.getRaffles();

      setShopItems(items.filter(({ comingSoon }: { comingSoon?: boolean }) => !comingSoon));
      setRaffles(raffles.filter(({ item }: { item?: any }) => !item?.comingSoon));
      setComingSoonItems([
        ...(items || []).filter(({ comingSoon }: any) => comingSoon),
        ...(raffles || []).filter(({ item }: any) => item?.comingSoon).map(({ item }: any) => item),
      ]);
    } catch (error) {
      setShopItems(null);
      setRaffles(null);
      setComingSoonItems(null);
    } finally {
      setLoading(false);
    }
  };

  const fetchItemsByCategory = async (category: ShopItemCategory) => {
    setLoading(true);
    try {
      const [items, raffles] = await Promise.all([shopApi.getShopItemsByCategory(category.id), shopApi.getRafflesByCategory(category.id)]);

      setShopItems(items.filter(({ comingSoon }: { comingSoon?: boolean }) => !comingSoon));
      setRaffles(raffles.filter(({ item }: { item?: any }) => !item?.comingSoon));
      setComingSoonItems([
        ...(items || []).filter(({ comingSoon }: any) => comingSoon),
        ...(raffles || []).filter(({ item }: any) => item?.comingSoon).map(({ item }: any) => item),
      ]);
    } catch (error) {
      setShopItems(null);
      setRaffles(null);
      console.log('fetchItemsByCategory error:', error);
    } finally {
      setLoading(false);
    }
  };

  const setShowAllItems = async () => {
    await fetchAllItems();
    setCurrentCategory(null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (currentCategory) {
      fetchItemsByCategory(currentCategory);
    } else {
      fetchData();
    }
  }, [currentCategory]);

  return (
    <Wrapper>
      <HeaderWrapper>
        <ControlsWrapper>
          <LevelButton theme={'secondary'} active={currentCategory === null} onClick={() => setShowAllItems()}>
            All
          </LevelButton>

          {categories?.sort((a: ShopItemCategory, b: ShopItemCategory) => (a.displayOrder < b.displayOrder ? -1 : 1)).map((category, index) => (
            <LevelButton key={index} theme={'secondary'} active={currentCategory === category}
                         onClick={() => setCurrentCategory(category)}>
              {category.label}
            </LevelButton>
          ))}

          <RefreshButton onClick={fetchData}>
            <RefreshIcon />
          </RefreshButton>
        </ControlsWrapper>
      </HeaderWrapper>

      <InternalContentGrid loading={loading} placeholder={{ ...Translations.shop.placeholder }}>
        {sortedItems.map((item, index) => {
          if (item.type === 'shop') {
            return <ShopItemCard key={index} item={item as ShopItemProps}
                                 loadLazy={isMobile ? index > 5 : index > 11} />;
          } else if (item.type === 'raffle') {
            return <RaffleTicketCard key={index} raffle={item as RaffleProps}
                                     loadLazy={isMobile ? index > 5 : index > 11} />;
          } else {
            return <ComingSoonCard key={index} {...(item as IComingSoonItem)} />;
          }
        })}
      </InternalContentGrid>
    </Wrapper>
  );
};
