import styled from 'styled-components';
import { useState } from 'react';
import ChevronIcon from '../../../icons/ChevronIcon';
import { Translations } from '../../../../utils/Translations';
import { PayloadWysiwyg } from '../../PayloadWysiwyg';
import Wysiwyg from '../../../common/Wysiwyg';
import { DetailPageSubHeadline } from './DetailPageStyledComponents';
import DetailPageSizeTable from './DetailPageSizeTable';
import { BREAKPOINT_MD } from '../../../../styles/Breakpoints';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-block-end: 3.75rem;

  ${BREAKPOINT_MD} {
    margin-block-end: 0;
  }
`;

const AccordionHead = styled(DetailPageSubHeadline).attrs({ as: 'button', type: 'button' })`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  text-transform: uppercase;
  padding-block: 1.5rem;

  svg {
    font-size: 1.5rem;
    transition: transform 0.25s ease-in-out;

    path {
      stroke-width: 2px;
    }
  }

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary-750);
    }
  }
`;

const AccordionBody = styled.div`
  display: grid;
  transition: grid-template-rows 0.25s ease-in-out;
`;

const Accordion = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  border-block-end: 1px solid var(--color-text-default);

  ${AccordionHead} {
    svg {
      transform: rotate(${({ open }) => (open ? 180 : 0)}deg);
    }
  }

  ${AccordionBody} {
    grid-template-rows: ${({ open }) => (open ? '1fr' : '0fr')};
  }
`;

const AccordionInnerBody = styled.div`
  overflow: hidden;

  > :last-child {
    margin-block-end: 1.25rem;
  }
`;

interface IDetailPageAccordionProps {
  purchaseInfo: string;
  productInfo: string;
}

export default function DetailPageAccordion(props: IDetailPageAccordionProps) {
  const { productInfo, purchaseInfo } = props;

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  return (
    <Wrapper>
      <Accordion open={currentIndex === 0}>
        <AccordionHead onClick={() => setCurrentIndex(currentIndex === 0 ? -1 : 0)}>
          {Translations.detailPage.accordion.purchaseInfoLabel} <ChevronIcon />
        </AccordionHead>

        <AccordionBody>
          <AccordionInnerBody>
            <Wysiwyg content={purchaseInfo.replace('|||', '')} />
          </AccordionInnerBody>
        </AccordionBody>
      </Accordion>

      <Accordion open={currentIndex === 1}>
        <AccordionHead onClick={() => setCurrentIndex(currentIndex === 1 ? -1 : 1)}>
          {Translations.detailPage.accordion.productInfoLabel} <ChevronIcon />
        </AccordionHead>

        <AccordionBody>
          <AccordionInnerBody>
            <PayloadWysiwyg content={productInfo || []} />
          </AccordionInnerBody>
        </AccordionBody>
      </Accordion>

      <Accordion open={currentIndex === 2}>
        <AccordionHead onClick={() => setCurrentIndex(currentIndex === 2 ? -1 : 2)}>
          {Translations.detailPage.accordion.sizeChartLabel} <ChevronIcon />
        </AccordionHead>

        <AccordionBody>
          <AccordionInnerBody>
            <AccordionInnerBody>
              <DetailPageSizeTable />
            </AccordionInnerBody>
          </AccordionInnerBody>
        </AccordionBody>
      </Accordion>
    </Wrapper>
  );
}
