import styled from 'styled-components';
import TextInput from '../../../common/form/TextInput';
import { BREAKPOINT_SM } from '../../../../styles/Breakpoints';
import Select from '../../../common/form/Select';
import { Countries } from '../../../../utils/Countries';
import { useMemo } from 'react';
import { IAddress } from '../../../../types/address';

const Wrapper = styled.div`
  display: grid;
  gap: 0.75rem 0.875rem;

  ${BREAKPOINT_SM} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StyledInput = styled(TextInput)`
  box-shadow: none;
`;

const StyledSelect = styled(Select)`
  select {
    box-shadow: none;
  }
`;

interface IAddressFormProps {
  prefix?: string;
  defaultValues?: IAddress;
  disabled?: boolean;
}

export default function AddressForm(props: IAddressFormProps) {
  const { prefix = '', defaultValues, disabled } = props;

  const formattedPrefix = useMemo(() => prefix.charAt(0).toUpperCase() + prefix.slice(1), [prefix]);

  const defaultSelectValue = useMemo(() => {
    const selectedItem = Countries.find(({ name }) => name === defaultValues?.country);
    return selectedItem?.value;
  }, [defaultValues]);

  return (
    <Wrapper>
      <StyledInput
        name={`firstname${formattedPrefix}`}
        placeholder={'First Name'}
        defaultValue={defaultValues?.firstname}
        disabled={disabled}
      />
      <StyledInput
        name={`lastname${formattedPrefix}`}
        placeholder={'Last Name'}
        defaultValue={defaultValues?.lastname}
        disabled={disabled}
      />
      <StyledInput name={`street${formattedPrefix}`} placeholder={'Street'} defaultValue={defaultValues?.street} disabled={disabled} />
      <StyledInput
        name={`houseNumber${formattedPrefix}`}
        placeholder={'House Number'}
        defaultValue={defaultValues?.houseNumber}
        disabled={disabled}
      />
      <StyledInput
        name={`postalCode${formattedPrefix}`}
        placeholder={'Postal Code'}
        defaultValue={defaultValues?.postalCode}
        disabled={disabled}
      />
      <StyledInput name={`city${formattedPrefix}`} placeholder={'City'} defaultValue={defaultValues?.city} disabled={disabled} />
      <StyledSelect
        name={`country${formattedPrefix}`}
        placeholder={defaultSelectValue ? undefined : 'Country'}
        items={Countries}
        defaultValue={defaultSelectValue}
        disabled={disabled}
      />
      <StyledInput
        name={`company${formattedPrefix}`}
        optional
        placeholder={'Company (optional)'}
        defaultValue={defaultValues?.company}
        disabled={disabled}
      />
      <StyledInput
        name={`phone${formattedPrefix}`}
        optional
        placeholder={'Phone (optional)'}
        defaultValue={defaultValues?.phone}
        disabled={disabled}
      />
    </Wrapper>
  );
}
