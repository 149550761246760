import InternalContentGrid from '../InternalContentGrid';
import { Translations } from '../../../utils/Translations';
import HistoryItemCard from './subcomponents/HistoryItemCard';
import { useEffect, useState } from 'react';
import { useShopApi } from '../../../services/shop';
import { useIsApiReady } from '../../../services/ApiProvider';
import { useRecoilState } from 'recoil';
import { HistoryItemState } from '../../../states/AppData';

export default function HistoryTab() {
  const [historyItemState, setHistoryItemState] = useRecoilState(HistoryItemState);
  const [loading, setLoading] = useState<boolean>(historyItemState.items === null);
  const isApiReady = useIsApiReady();
  const shopApi = useShopApi();

  useEffect(() => {
    const fetchHistoryItems = async () => {
      try {
        const items = await shopApi.getShopHistory();
        setHistoryItemState({ items });
      } catch (error) {
        console.log('error fetching history items:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isApiReady) {
      fetchHistoryItems();
    }
  }, [isApiReady]);

  return (
    <InternalContentGrid loading={loading} placeholder={Translations.shopHistory.placeholder}>
      {historyItemState.items?.map((item, index) => <HistoryItemCard key={item.id} {...item} loadLazy={index > 11} />)}
    </InternalContentGrid>
  );
}
