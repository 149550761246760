import styled from 'styled-components';
import { useMemo } from 'react';
import { useCountdown } from '../../../hooks/useCountdown';
import { Translations } from '../../../utils/Translations';

export const CardCountdownBanner = styled.div<{itemAvailable: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //padding-inline: 2rem;
  padding-inline: 10.66cqw;
  //padding-block: .375rem;
  padding-block: 2cqw;
  font-family: var(--font-family-headline);
  text-transform: uppercase;
  //font-size: .75rem;
  font-size: 4cqw;
  line-height: 1;

  background: ${props => (props.itemAvailable ? `var(--color-signal-red)` : `var(--color-grey-650)`)};
  color: ${props => (props.itemAvailable ? `var(--color-black-900)` : `var(--color-grey-600)`)};
`;

const StyledCardCountdownBanner = styled(CardCountdownBanner)`
  justify-content: center;
`;

export const CountdownLabel = styled.p`
    color: inherit
`;

export const CountdownTime = styled.div`
  display: flex;
  //letter-spacing: 0.15rem;
  letter-spacing: 0.8cqw;
`;

interface ICardCountdown {
  startDate?: string;
  endDate?: string;
  placeholder?: string;
}

export default function CardCountdown(props: ICardCountdown) {
  const { startDate, endDate, placeholder } = props;

  const countdownDate = useMemo(() => {
    if (startDate && new Date(startDate).getTime() > new Date().getTime()) {
      return startDate;
    } else if (endDate && new Date(endDate).getTime() > new Date().getTime()) {
      return endDate;
    } else return null;
  }, [startDate, endDate]);

  const [days, hours, minutes, seconds] = useCountdown(countdownDate || '');

  return (
    <>
      {placeholder ? (
        <StyledCardCountdownBanner itemAvailable={false}>
          <CountdownLabel>{placeholder}</CountdownLabel>
        </StyledCardCountdownBanner>
      ) : (
        <>
          {countdownDate && (
            <CardCountdownBanner itemAvailable={true}>
              <CountdownLabel>
                {startDate && new Date(startDate).getTime() > new Date().getTime()
                  ? Translations.cards.countdown.startsIn
                  : endDate && new Date(endDate).getTime() > new Date().getTime()
                  ? Translations.cards.countdown.endsIn
                  : Translations.cards.countdown.ended}
              </CountdownLabel>

              <CountdownTime>
                {parseInt(days) > 0 && <p>{days}:</p>}
                <p>{hours}:</p>
                <p>{minutes}:</p>
                <p>{seconds}</p>
              </CountdownTime>
            </CardCountdownBanner>
          )}

          {!countdownDate && endDate && new Date(endDate).getTime() < new Date().getTime() && (
            <StyledCardCountdownBanner itemAvailable={false}>
              <CountdownLabel>{Translations.cards.countdown.ended}</CountdownLabel>
            </StyledCardCountdownBanner>
          )}
        </>
      )}
    </>
  );
}
