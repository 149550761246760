// General
import { useCallback, useMemo } from 'react';
import { Slide, ToastOptions, toast } from 'react-toastify';

// State
import { useTransactionState } from '../states/TransactionState';

// Types
import { TNotification } from '../types/page';
import { ITransactionToastProps } from '../components/content/toasts/TransactionToast';
import { TypeOptions } from 'react-toastify/dist/types';

// Components
import TransactionToast from '../components/content/toasts/TransactionToast';
import Toast, { ToastContent, ToastHeadline } from '../components/content/toasts/Toast';
import ErrorToast from '../components/content/toasts/ErrorToast';

interface IAddToastProps extends TNotification {
  type: TypeOptions;
  autoClose?: number | false;
}

export default function useToast() {
  const { addTx, removeTx } = useTransactionState();

  const TOAST_BASE: ToastOptions = useMemo(
    () => ({
      closeOnClick: false,
      position: 'bottom-right',
      transition: Slide,
      closeButton: <></>,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: false,
    }),
    [],
  );

  const showTransactionToast = useCallback(
    (props: ITransactionToastProps) => {
      toast(<TransactionToast {...props} />, {
        ...TOAST_BASE,
        autoClose: false,
        onClose: () => removeTx(props.txHash),
      });
    },
    [removeTx, TOAST_BASE],
  );

  const addTransactionToast = useCallback(
    (props: ITransactionToastProps) => {
      addTx(props.txHash, props.transactionType);
      showTransactionToast(props);
    },
    [addTx, showTransactionToast],
  );

  const addErrorToast = useCallback(
    (props: TNotification) => {
      toast(<ErrorToast {...props} />, {
        ...TOAST_BASE,
        autoClose: 5000,
        progressClassName: 'error',
      });
    },
    [TOAST_BASE],
  );

  const addToast = useCallback(
    ({ headline, text, type, autoClose = false }: IAddToastProps) => {
      toast(
        <Toast type={type}>
          <ToastHeadline>{headline}</ToastHeadline>

          <ToastContent content={text} />
        </Toast>,
        {
          ...TOAST_BASE,
          autoClose,
          progressClassName: type,
        },
      );
    },
    [TOAST_BASE],
  );

  return { addTransactionToast, showTransactionToast, addErrorToast, addToast };
}
