import styled from 'styled-components';

const Image = styled.img`
  position: fixed;
  inset: 0;
  block-size: 100%;
  object-fit: cover;
  z-index: -1;
  width: 100%;
  object-position: bottom;
`;

export default function Background() {
  return (
    <picture>
      {/*<source media={`(min-width: ${DIMEN_BREAKPOINT_XL}px)`} srcSet={'/images/background_desktop.jpg'} />*/}
      <source media={`(orientation: landscape)`} srcSet={'/images/background_desktop.jpg'} />
      <Image src={'/images/background_mobile.jpg'} alt={'background image'} />
    </picture>
  );
}
