import { SVGProps } from 'react';

export default function DeleteIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.14286 3.5H14.8571L15.4286 4.625H20V6.875H4V4.625H8.57143L9.14286 3.5ZM5.14286 8H18.8571V21.5H5.14286V8ZM9.14286 10.25H8V10.8125V18.6875V19.25H9.14286V18.6875V10.8125V10.25ZM12.5714 10.25H11.4286V10.8125V18.6875V19.25H12.5714V18.6875V10.8125V10.25ZM16 10.25H14.8571V10.8125V18.6875V19.25H16V18.6875V10.8125V10.25Z'
        fill='currentColor'
      />
    </svg>
  );
}
