// General
import { FC } from 'react';
import styled from 'styled-components';

// Styles
import { Headline3 } from '../../styles/FontStyles';

const StyledContent = styled.div`
  display: grid;
  gap: 1rem;

  h1,
  h2 {
    ${Headline3};
    font-weight: 700;
  }

  a {
    text-decoration: underline;

    @media (hover: hover) {
      &:hover {
        color: var(--color-primary-750);
      }
    }

    u {
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }
  }
`;

export interface IWysiwygProps {
  content: string;
  className?: string;
  id?: string;
}

export const Wysiwyg: FC<IWysiwygProps> = ({ content, className, id }) => (
  <StyledContent id={id} className={className} dangerouslySetInnerHTML={{ __html: content }} />
);

export default Wysiwyg;
