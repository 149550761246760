import { css } from 'styled-components';

export const HideScrollbarStyles = css`
  &::-webkit-scrollbar {
    display: none; // Chrome, Safari and Opera
  }

  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
`;

export const Glass = css`
  background: var(--theme-black-black-50, rgba(0, 0, 0, 0.5));
  border-radius: 20px;
  border: 1px solid var(--base-grey-700, #666);
  backdrop-filter: blur(5px);
`;
