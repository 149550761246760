import styled from 'styled-components';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Address, useAccount, useContractWrite, usePrepareContractWrite } from 'wagmi';
import { useRecoilState } from 'recoil';
import { waitForTransaction } from '@wagmi/core';
import { NFTPlaceholder } from '../NFTPlaceholder';
import { InternalStyledButton, Wrapper } from '../Container';
import LoadingSpinner from '../../common/LoadingSpinner';
import TermsModal from '../TermsModal';
import StickyActionBar from '../../common/StickyActionBar';
import CardGallery from '../cards/CardGallery';
import RarityCard, { IRarityCardProps } from '../cards/RarityCard';
import { BallerRarities, Notifications } from '../../layout/app/Layout';
import useToast from '../../../hooks/useToast';
import getOpenSeaUrl from '../../../utils/getOpenSeaUrl';
import { getBoosterId, getCometsId } from '../../../utils/getNFTId';
import { Translations } from '../../../utils/Translations';
import { BallerState, BoosterState, CometsState, RaffleTicketState, ShopItemState } from '../../../states/AppData';
import BallerABI from '../../../utils/abi/baller_abi.json';
import useFeatureToggle from '../../../hooks/useFeatureToggle';
import BoosterCard from '../cards/BoosterCard';
import CometCard from '../cards/CometCard';
import ShopItemCard from '../cards/ShopItemCard';
import RaffleTicketCard from '../cards/RaffleTicketCard';
import { useShopApi } from '../../../services/shop';
import { useIsApiReady } from '../../../services/ApiProvider';

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  --width: 4px;
  font-size: 1.5rem;
`;

const OpenBoosterButton = styled(InternalStyledButton)<{ isLoading: boolean }>`
  display: grid;
  place-items: center;

  > * {
    grid-area: 1 / 1;
    transition: opacity 0.25s ease-in-out;
  }

  > :first-child {
    pointer-events: none;
    opacity: ${({ isLoading }) => (isLoading ? '1' : '0')};
  }

  > :last-child {
    opacity: ${({ isLoading }) => (isLoading ? '0' : '1')};
  }
`;

export default function Inventory() {
  const { addErrorToast, addTransactionToast } = useToast();
  const { REVEAL_ACTIVE } = useFeatureToggle();
  const { address } = useAccount();
  const notifications = useContext(Notifications);
  const featureToggles = useFeatureToggle();

  const [ballers, setBallers] = useRecoilState(BallerState);
  const [boosters, setBoosters] = useRecoilState(BoosterState);
  const [comets, setComets] = useRecoilState(CometsState);
  const [shopItems, setShopItems] = useRecoilState(ShopItemState);
  const [raffleTickets, setRaffleTickets] = useRecoilState(RaffleTicketState);
  const ballerRarities = useContext(BallerRarities);

  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [selectedBoosters, setSelectedBoosters] = useState<number[]>([]);

  const hasInitialData = useMemo(
    () =>
      ballers.nfts !== null && boosters.nfts !== null && comets.nfts !== null && raffleTickets.items !== null && shopItems.items !== null,
    [ballers, boosters, comets, raffleTickets, shopItems],
  );

  const [loading, setLoading] = useState<boolean>(!hasInitialData);
  const [openAccordion, setOpenAccordion] = useState<'booster' | 'baller' | 'comet' | 'shop' | 'raffle' | null>(null);

  const shopApi = useShopApi();
  const isApiReady = useIsApiReady();

  const populatedBallers = useMemo(
    () =>
      ballers.nfts?.map(
        (baller) =>
          ({
            ...baller,
            ...ballerRarities?.find(
              ({ ballerClass }) => baller.attributes.find(({ trait_type }) => trait_type === 'Class')?.value === ballerClass,
            ),
          }) as IRarityCardProps,
      ),
    [ballers, ballerRarities],
  );

  const populatedBoosters = useMemo(() => {
    const formattedBooster = (boosters.nfts || []).map(({ name, image, wallet }) => ({
      id: parseInt(getBoosterId(name)),
      title: process.env.REACT_APP_BOOSTER_NAME_BASE!.slice(0, -1),
      image: image,
      wallet,
    }));

    const connectedBoosters = formattedBooster.filter(({ wallet }) => wallet === address).sort((a, b) => a.id - b.id);
    const notConnectedBoosters = formattedBooster?.filter(({ wallet }) => wallet !== address).sort((a, b) => a.id - b.id);

    return [...connectedBoosters, ...notConnectedBoosters];
  }, [boosters, address]);

  const populatedComets = useMemo(
    () =>
      comets.nfts?.map(({ name, image }) => ({
        id: parseInt(getCometsId(name)),
        title: process.env.REACT_APP_COMETS_NAME_BASE!.slice(0, -1),
        image: image,
      })),
    [comets],
  );

  const hasAssets = useMemo(
    () =>
      !!boosters?.nfts?.length ||
      !!ballers?.nfts?.length ||
      !!comets?.nfts?.length ||
      !!shopItems?.items?.length ||
      !!raffleTickets?.items?.length,
    [boosters, ballers, comets, shopItems, raffleTickets],
  );

  const { config } = usePrepareContractWrite({
    address: process.env.REACT_APP_BALLER_CONTRACT_ADDRESS as Address,
    abi: BallerABI,
    functionName: 'claim',
    args: [selectedBoosters],
    enabled: !!selectedBoosters.length,
  });

  const { data: txId, isLoading, write, isSuccess } = useContractWrite(config);

  const openBoosters = () => {
    write?.();
    setShowTerms(false);
  };

  const handleSelectAll = useCallback(() => {
    const boostersOnCurrentWallet = populatedBoosters?.filter(({ wallet }) => wallet === address);
    boostersOnCurrentWallet && setSelectedBoosters(boostersOnCurrentWallet.map(({ id }) => id));
  }, [populatedBoosters, address]);

  const fetchData = useCallback(async () => {
    try {
      const inventory = await shopApi.getInventory();

      setBallers({ nfts: inventory.nfts.ballers });
      setBoosters({ nfts: inventory.nfts.boosters });
      setComets({ nfts: inventory.nfts.comets });
      featureToggles.SHOP_ACTIVE && setShopItems({ items: inventory.items });
      featureToggles.SHOP_ACTIVE && setRaffleTickets({ items: inventory.raffles });
    } catch {
      addErrorToast(notifications!.internalServerError);
    } finally {
      setLoading(false);
    }
  }, [notifications]);

  useEffect(() => {
    if (isApiReady) {
      fetchData();
    }
  }, [isApiReady, fetchData]);

  const handleSelect = (id: number) => {
    if (selectedBoosters.includes(id)) {
      setSelectedBoosters(selectedBoosters.filter((item) => item !== id));
    } else {
      setSelectedBoosters([...selectedBoosters, id]);
    }
  };

  const waitForSuccess = async () => {
    try {
      await waitForTransaction({ hash: txId!.hash });
      fetchData();
    } catch {
      console.log('failed to track transaction progress!');
    }
  };

  useEffect(() => {
    if (isSuccess && txId?.hash) {
      setBoosters({ nfts: [] });
      setSelectedBoosters([]);
      waitForSuccess();
    }
  }, [isSuccess]);

  useEffect(() => {
    txId &&
      addTransactionToast({
        txHash: txId.hash,
        transactionType: 'reveal',
      });
  }, [txId]);

  return (
    <Wrapper>
      {loading || !hasAssets ? (
        <NFTPlaceholder {...Translations.inventory.placeholder} loading={loading} />
      ) : (
        <>
          {!!populatedBoosters?.length && (
            <CardGallery
              headline={'Booster'}
              open={openAccordion === 'booster'}
              toggleOpen={() => setOpenAccordion(openAccordion === 'booster' ? null : 'booster')}>
              {populatedBoosters.map((booster, index) => (
                <BoosterCard
                  {...booster}
                  key={index}
                  openseaLink={{
                    href: getOpenSeaUrl('booster', booster.id),
                    title: 'OpenSea',
                    target: '_blank',
                  }}
                  selected={selectedBoosters.includes(booster.id)}
                  toggleSelect={REVEAL_ACTIVE ? () => handleSelect(booster.id) : undefined}
                />
              ))}
            </CardGallery>
          )}

          {!!populatedBallers?.length && (
            <CardGallery
              headline={'Baller'}
              open={openAccordion === 'baller'}
              toggleOpen={() => setOpenAccordion(openAccordion === 'baller' ? null : 'baller')}>
              {populatedBallers.map((baller, index) => (
                <RarityCard key={index} {...baller} />
              ))}
            </CardGallery>
          )}

          {!!populatedComets?.length && (
            <CardGallery
              headline={'Comets'}
              open={openAccordion === 'comet'}
              toggleOpen={() => setOpenAccordion(openAccordion === 'comet' ? null : 'comet')}>
              {populatedComets.map((comet, index) => (
                <CometCard
                  {...comet}
                  key={index}
                  openseaLink={{
                    href: getOpenSeaUrl('comet', comet.id),
                    title: 'OpenSea',
                    target: '_blank',
                  }}
                />
              ))}
            </CardGallery>
          )}

          {featureToggles.SHOP_ACTIVE && !!shopItems?.items?.length && (
            <CardGallery
              headline={'Shop Items'}
              open={openAccordion === 'shop'}
              toggleOpen={() => setOpenAccordion(openAccordion === 'shop' ? null : 'shop')}>
              {shopItems.items.map((item, index) => (
                <ShopItemCard {...item} key={index} />
              ))}
            </CardGallery>
          )}

          {featureToggles.SHOP_ACTIVE && !!raffleTickets.items?.length && (
            <CardGallery
              headline={'Raffle Tickets'}
              open={openAccordion === 'raffle'}
              toggleOpen={() => setOpenAccordion(openAccordion === 'raffle' ? null : 'raffle')}>
              {raffleTickets.items.map((raffleTicket, index) => (
                <RaffleTicketCard {...raffleTicket} key={index} />
              ))}
            </CardGallery>
          )}
        </>
      )}

      {REVEAL_ACTIVE && (
        <StickyActionBar open={selectedBoosters.length > 0} onClose={() => setSelectedBoosters([])}>
          <ButtonWrapper>
            <InternalStyledButton
              theme={'secondary'}
              disabled={selectedBoosters.length === boosters.nfts?.filter(({ wallet }) => wallet === address).length}
              onClick={handleSelectAll}>
              {Translations.inventory.selectAllLabel}
            </InternalStyledButton>

            <OpenBoosterButton
              theme={'secondary'}
              onClick={() => setShowTerms(true)}
              disabled={!write && !selectedBoosters.length}
              isLoading={isLoading}>
              <StyledLoadingSpinner />
              <span>{Translations.inventory.openBoosterLabel}</span>
            </OpenBoosterButton>
          </ButtonWrapper>

          {showTerms && (
            <TermsModal
              close={() => setShowTerms(false)}
              content={Translations.inventory.revealTermsAndConditions}
              onAccept={openBoosters}
              acceptText={Translations.inventory.openBoosterLabel}
            />
          )}
        </StickyActionBar>
      )}
    </Wrapper>
  );
}
