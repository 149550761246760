import styled from 'styled-components';
import { DashboardHeader, DashboardHeadline } from './index';
import { INewsImage } from '../../../types/news';
import { ILink } from '../../../types/link';
import { BREAKPOINT_MD, BREAKPOINT_XXL, DIMEN_BREAKPOINT_MD } from '../../../styles/Breakpoints';
import LinkHandler from '../../common/LinkHandler';
import { Glass } from '../../../styles/Custom';

const Button = styled.button`
  font-family: var(--font-family-headline);
  text-decoration: underline;
  font-weight: 700;
  white-space: nowrap;
  justify-self: flex-start;

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary-750);
    }
  }

  ${BREAKPOINT_MD} {
    grid-area: 2 / 2;
    align-self: flex-end;
  }
`;

const Wrapper = styled.div`
  grid-column: 1 / -1;

  ${BREAKPOINT_XXL} {
    grid-column: 13 / 21;
  }
`;

const GlassCard = styled(LinkHandler)`
  ${Glass};
  padding: 1.25rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${BREAKPOINT_MD} {
    padding: 1.25rem;
    gap: 1.25rem;
  }

  &:active,
  &:hover {
    text-decoration: none;
  }

  &:hover {
    ${Button} {
      color: var(--color-primary-750);
    }
  }
`;

const Content = styled.div`
  display: grid;
  gap: 0.625rem;

  ${BREAKPOINT_MD} {
    grid-template-columns: 1fr min-content;
    column-gap: 2.5rem;
  }

  ${BREAKPOINT_XXL} {
    column-gap: 1.5rem;
  }
`;

const ImageWrapper = styled.div`
  border-radius: 1.25rem;
  overflow: hidden;
  display: flex;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.8);
`;

const Image = styled.img`
  aspect-ratio: 1 / 1;
  width: 100%;

  ${BREAKPOINT_MD} {
    aspect-ratio: 2 / 1;
  }
`;

const Title = styled.h5`
  text-transform: uppercase;
  font-size: 1.625rem;
  line-height: 1.2;

  ${BREAKPOINT_MD} {
    font-size: 1.875rem;
    grid-area: 1 / 1 / 1 / 3;
  }
`;

const Text = styled.p`
  line-height: 1.3;

  ${BREAKPOINT_MD} {
    font-size: 0.875rem;
    grid-area: 2 / 1;
  }
`;

export interface IInfoCardProps {
  headline: string;
  card: {
    title: string;
    previewText?: string;
    image: INewsImage;
    link: ILink;
  };
}

export default function InfoCard(props: IInfoCardProps) {
  const { headline, card } = props;
  const { image, title, previewText, link } = card;

  return (
    <Wrapper>
      <DashboardHeader>
        <DashboardHeadline>{headline}</DashboardHeadline>
      </DashboardHeader>

      <GlassCard to={link.href} target={link.target}>
        <ImageWrapper>
          <picture>
            <source media={`(min-width:${DIMEN_BREAKPOINT_MD}px)`} srcSet={image.sizes.previewDesktop.url} />
            <Image src={image.sizes.previewMobile.url} alt={image.alt} />
          </picture>
        </ImageWrapper>

        <Content>
          <Title>{title}</Title>

          {previewText && <Text>{previewText}</Text>}

          <Button>{link.title}</Button>
        </Content>
      </GlassCard>
    </Wrapper>
  );
}
