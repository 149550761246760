import { FC, useContext } from 'react';
import { Connector, useConnect, useSignMessage } from 'wagmi';
import styled, { css } from 'styled-components';
import { dispatchWalletConnectingFailedEvent } from '../../../utils/walletConnect/event';
import { Notifications, WalletConnectTranslations } from '../app/Layout';
import CoinbaseWallet from '../../icons/CoinbaseIcon';
import Metamask from '../../icons/Metamask';
import WalletConnect from '../../icons/WalletConnectIcon';
import Button from './Button';
import WalletConnectModal, { IModalProps } from './WalletConnectModal';
import Wysiwyg from '../../common/Wysiwyg';
import { BREAKPOINT_LG, BREAKPOINT_SM } from '../../../styles/Breakpoints';
import { useUserApi } from '../../../services/user';
import useToast from '../../../hooks/useToast';
import { useRecoilState } from 'recoil';
import { AccountState, WalletState } from '../../../states/AppData';
import { AxiosResponse } from 'axios';

const TextStyles = css`
  text-align: center;
  color: #000;
`;

export const Headline = styled.h2`
  ${TextStyles};
  line-height: 1.2;
  font-size: 1.875rem;
  margin-bottom: 1rem;
  font-family: var(--font-family-headline);
  text-transform: uppercase;

  ${BREAKPOINT_LG} {
    font-size: 2.5rem;
  }
`;

export const IntroText = styled(Wysiwyg)`
  ${TextStyles};
  font-family: var(--font-family-default);
  line-height: 1.5;
  max-width: 450px;
  font-size: 1rem;

  &,
  p {
    &:not(:last-child) {
      margin: 0 auto 2rem;

      ${BREAKPOINT_LG} {
        margin: 0 auto 2.5rem;
      }
    }
  }
`;

const WalletConnectWrapper = styled.div`
  display: grid;
  gap: 1rem;
  justify-items: center;
`;

export const WalletButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.875rem;
  padding: 0;
  min-width: 210px;

  ${BREAKPOINT_SM} {
    min-width: 320px;
  }
`;

const WalletIcon = styled.div`
  height: 28px;
  width: 28px;
`;

const ConnectWallet: FC<IModalProps> = ({ close }) => {
  const t = useContext(WalletConnectTranslations);
  const notifications = useContext(Notifications);

  const [walletState, setWalletState] = useRecoilState(WalletState);
  const [accountState, setAccountState] = useRecoilState(AccountState);

  const { connectAsync, connectors, isLoading } = useConnect();
  const { addToast, addErrorToast } = useToast();
  const userApi = useUserApi();
  const { signMessageAsync } = useSignMessage();

  const verifyWallet = async (wallet: string) => {
    const { message, nonce } = await userApi.getNonce();
    const signedMessage = await signMessageAsync({ message });
    const verifyNonceResponse = await userApi.verifyNonce(wallet, signedMessage, nonce);

    try {
      const connectResponse = await userApi.connectWallet({
        wallet: wallet,
        walletAuthorization: (verifyNonceResponse as any).token,
      });

      if (connectResponse.pointsAdded > 0) {
        if (notifications) {
          addToast({
            headline: notifications.pointsAddedViaWalletConnect.headline,
            text: notifications.pointsAddedViaWalletConnect.text.replace('{POINTS}', connectResponse.pointsAdded.toString()),
            type: 'success',
          });
        }
      }

      setWalletState(connectResponse.wallets);

      if (accountState) {
        setAccountState({
          ...accountState,
          account: {
            ...accountState.account,
            points: accountState.account.points + (connectResponse?.pointsAdded || 0),
            level: connectResponse?.level.toString() as any,
          },
        });
      } else {
        userApi.refreshAccountState();
      }
    } catch (error: any) {
      console.log(error);
      if (notifications) {
        if (error?.response?.status === 409) {
          addErrorToast({
            headline: notifications.walletAlreadyConnected.headline,
            text: notifications.walletAlreadyConnected.text,
          });
        } else {
          addErrorToast(notifications.internalServerError);
        }
      }
    }
  };

  const connect = async (connector: Connector) => {
    try {
      close();
      const wagmiResponse = await connectAsync({ connector });

      if (walletState?.filter((item) => item.wallet === wagmiResponse.account).length === 0) {
        verifyWallet(wagmiResponse.account);
      }
    } catch (err: any) {
      console.log(err);
      if (notifications) {
        addErrorToast(notifications.internalServerError);
      }
      dispatchWalletConnectingFailedEvent(err);
    }
  };

  const formatString = (value: string) => {
    return value.replaceAll(' ', '-').toLocaleLowerCase();
  };

  const renderWalletIcon = (name: string) => {
    if (name === 'metamask') {
      return <WalletIcon as={Metamask} aria-hidden='true' />;
    } else if (name === 'coinbase-wallet') {
      return <WalletIcon as={CoinbaseWallet} aria-hidden='true' />;
    } else {
      return <WalletIcon as={WalletConnect} aria-hidden='true' />;
    }
  };

  return (
    <WalletConnectModal close={close}>
      <Headline>{t?.connectHeadline}</Headline>

      <IntroText content={t?.connectText!} />

      <WalletConnectWrapper>
        {connectors.map((connector) => (
          <WalletButton theme={'secondary'} disabled={!connector.ready || isLoading} key={connector.id} onClick={() => connect(connector)}>
            {renderWalletIcon(formatString(connector.name))}
            {connector.name}
          </WalletButton>
        ))}
      </WalletConnectWrapper>
    </WalletConnectModal>
  );
};

export default ConnectWallet;
