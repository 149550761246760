// General
import { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

// Component
import CheckmarkIcon from '../icons/CheckmarkIcon';

// Stlyes
import { Body1, Body2 } from '../../styles/FontStyles';
import { Colors } from '../../styles/Colors';

const Checkbox = styled.input.attrs({ type: 'checkbox', autoComplete: 'off' })`
  flex-shrink: 0;
  ${Body1};
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid ${Colors.primary};
  border-radius: 4px;
  filter: drop-shadow(0px 0px 10px var(--color-drop-shadow, --color-primary-rgba)) drop-shadow(0px 0px 40px ${Colors.primary});

  transition: filter 150ms ease-out, border-color 150ms ease-out;
`;

const Label = styled.label`
  ${Body2};
  padding: 3px 0 3px 1rem;
  cursor: pointer;
  color: var(--label-color, --color-text-default);
  transition: color 150ms ease-out;

  a {
    text-decoration: underline;
    pointer-events: all;
  }
`;

const InputWrapper = styled.div<{ error?: boolean; disabled?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 500px;

  ${({ error = false }) =>
    error &&
    css`
      input {
        border-color: var(--color-signal-red);
        filter: none;
      }

      label,
      svg {
        color: var(--color-signal-red);
      }
    `};

  ${({ disabled = false }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
`;

const Checkmark = styled(CheckmarkIcon)<{ checked: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  padding: 5px;
  pointer-events: none;
  user-select: none;

  transition: opacity 150ms ease-out, visibility 150ms ease-out, color 150ms ease-out;
  opacity: ${({ checked }) => (checked ? 1 : 0)};
  visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};

  color: ${Colors.primary};
`;

export const CheckboxField: FC<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { label: string; error?: boolean }
> = ({ id, label, error, ...props }) => (
  <InputWrapper error={error} disabled={props.disabled}>
    <Checkbox id={id} {...(props as any)} />
    <Checkmark checked={!!props.checked} />
    <Label htmlFor={id} dangerouslySetInnerHTML={{ __html: label }} />
  </InputWrapper>
);
