import styled from 'styled-components';
import { useMemo } from 'react';
import { HideScrollbarStyles } from '../../styles/Custom';

const Wrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  ${HideScrollbarStyles};
`;

const Header = styled.button`
  display: grid;
  border-radius: 3.125rem;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid var(--color-grey-650);
  backdrop-filter: blur(0.375rem);
  padding: 0.25rem;
  flex-shrink: 0;
`;

const Tab = styled.div<{ active: boolean }>`
  z-index: 1;
  padding: 0.625rem 1rem;
  color: ${({ active }) => (active ? 'var(--color-primary-750)' : 'var(--color-text-default)')};
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.25s ease-in-out;
  grid-row: 1;
  white-space: nowrap;
`;

const Indicator = styled.span<{ currentIndex: number }>`
  grid-row: 1;
  grid-column: 1 / -1;
  display: grid;
  height: 2.75rem;
  transition: grid-template-columns 0.25s ease-in-out;

  ::after {
    content: '';
    grid-column: 2;
    border-radius: 3.125rem;
    border: 1px solid var(--color-primary-750);
    background: var(--color-text-highlight);
    box-shadow: 0 0 10px 0 var(--color-primary-750) inset;
    transition: grid-column 1ms ease-in-out;
  }
`;

interface ITabSwitchProps {
  className?: string;
  currentIndex: number;
  onClick: (index: number) => void;
  items: {
    label: string;
    path: string;
  }[];
}

export default function AnimatedTabSwitch(props: ITabSwitchProps) {
  const { className, currentIndex, items, onClick } = props;

  const indicatorStyles = useMemo(() => {
    const first = `${currentIndex}fr`;
    const last = `${items.length - currentIndex - 1}fr`;
    return { gridTemplateColumns: `${first} 1fr ${last}` };
  }, [currentIndex, items]);

  return (
    <Wrapper className={className}>
      <Header style={{ gridTemplateColumns: `repeat(${items.length}, 1fr)` }}>
        {items.map((item, index) => (
          <Tab key={index} active={index === currentIndex} onClick={() => onClick(index)} style={{ gridColumn: index + 1 }}>
            {item.label}
          </Tab>
        ))}

        <Indicator currentIndex={currentIndex} style={indicatorStyles} />
      </Header>
    </Wrapper>
  );
}
