import styled from 'styled-components';
import { Button } from '../../../common/Button';
import OpenSeaIcon from '../../../icons/OpenSeaIcon';
import Mooncourt from '../../../icons/Mooncourt';
import LinkHandler from '../../../common/LinkHandler';
import { BREAKPOINT_MD } from '../../../../styles/Breakpoints';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1.25rem;
  border: 1px solid #fff;
  background: rgba(13, 13, 13, 0.1);
  backdrop-filter: blur(6px);
  align-items: center;
  padding: 1.5rem 1rem;
  margin-block: auto;

  ${BREAKPOINT_MD} {
    padding: 1.5rem 2rem;
  }
`;

const StyledLogo = styled(Mooncourt)`
  color: var(--color-primary-750);
  font-size: 2rem;
  margin-block-end: 0.25rem;
`;

const Headline = styled.h4`
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-block-end: 0.75rem;
  font-weight: 700;
`;

const Text = styled.p`
  font-size: 1rem;
  text-align: center;
  margin-block-end: 1.5rem;
  line-height: 1.25;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  ${BREAKPOINT_MD} {
    width: auto;
  }
`;

const StyledLink = styled(LinkHandler)`
  :hover {
    text-decoration: none;
  }

  button {
    width: 100%;
    justify-content: center;

    ${BREAKPOINT_MD} {
      width: 15rem;
    }
  }
`;

export default function TeamPlaceholder() {
  return (
    <Wrapper>
      <StyledLogo />

      <Headline>Get ballers now</Headline>

      <Text>Assemble your team of ballers to collect Moonpoints, which will allow you to participate in exclusive sneaker raffles.</Text>

      <ButtonWrapper>
        <StyledLink to={'https://www.mooncourt.xyz/#buypass'}>
          <Button>
            <Mooncourt /> MVP Pass
          </Button>
        </StyledLink>

        <StyledLink to={'https://opensea.io/de-DE/collection/mooncourt-baller'}>
          <Button>
            <OpenSeaIcon /> Baller
          </Button>
        </StyledLink>

        <StyledLink to={'https://opensea.io/de-DE/collection/mooncourt-booster'}>
          <Button>
            <OpenSeaIcon /> Booster
          </Button>
        </StyledLink>
      </ButtonWrapper>
    </Wrapper>
  );
}
