import { SVGProps } from 'react';

export default function TrophyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox='0 0 22 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.417 13.8158C12.2375 13.9086 12.0694 13.9828 11.9167 14.0422V17.2188H14.9722H15.8889V19H14.9722H11H7.02778H6.11111V17.2188H7.02778H10.0833V14.0422C9.93056 13.9828 9.7625 13.9086 9.58299 13.8158C8.29201 13.5932 5.93542 12.9994 3.87292 11.452C1.75313 9.85996 0 7.29199 0 3.26562V2.375H0.916667H4.95C4.93854 2.18203 4.9309 1.98164 4.92326 1.78125C4.90035 1.21348 4.88889 0.619727 4.88889 0H6.72222H15.2778H17.1111C17.1111 0.619727 17.0997 1.21348 17.0767 1.78125C17.0691 1.98164 17.0615 2.18203 17.05 2.375H21.0833H22V3.26562C22 7.29199 20.2469 9.85996 18.1271 11.452C16.0684 12.9994 13.708 13.5895 12.417 13.8158ZM14.9264 11.2293C15.6177 10.9324 16.3319 10.5465 17.0042 10.0418C18.5549 8.88027 19.9031 7.06563 20.1323 4.15625H16.9087C16.5611 7.4998 15.7934 9.73379 14.9264 11.2293ZM5.0875 4.15625H1.86389C2.09306 7.06563 3.44132 8.88027 4.99201 10.0418C5.66042 10.5465 6.37847 10.9324 7.06979 11.2293C6.20278 9.73379 5.43507 7.4998 5.0875 4.15625ZM9.35382 11.3295C9.91528 11.9863 10.4271 12.2572 10.7288 12.376C10.8472 12.4205 10.9427 12.4465 11.0038 12.4613C11.0649 12.4502 11.1604 12.4242 11.2788 12.376C11.5806 12.2609 12.0885 11.9863 12.6538 11.3295C13.6889 10.1197 15.0181 7.4627 15.251 1.78125H6.7566C6.98958 7.4627 8.31493 10.1197 9.35382 11.3295ZM11.8976 5.03574L13.9066 5.31777L12.4552 6.69453L12.799 8.63906L11 7.71875L9.20486 8.63535L9.54861 6.69082L8.09722 5.31406L10.1062 5.03203L11 3.26562L11.8976 5.03203V5.03574Z'
        fill='currentColor'
      />
    </svg>
  );
}
