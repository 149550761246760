import styled from 'styled-components';
import { useMemo } from 'react';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
  margin-block-end: 12px;
`;

const Item = styled.div<{ isValid: boolean }>`
  font-size: 0.75rem;
  line-height: 1.3;
  color: var(${({ isValid }) => (isValid ? '--color-signal-green' : '--color-grey-650')});
`;

export function validatePassword(password: string) {
  const includesCapital = /[A-Z]/g.test(password || '');
  const includesSymbol = /[^\w\d]/g.test(password || '');
  const includesNumber = /[0-9]/g.test(password || '');
  const hasRequiredLength = /.{8,}$/g.test(password || '');

  return includesCapital && includesSymbol && includesNumber && hasRequiredLength;
}

interface ICustomPasswordValidationProps {
  value: string;
}

export default function CustomPasswordValidation(props: ICustomPasswordValidationProps) {
  const { value } = props;

  const includesCapital = useMemo(() => /[A-Z]/g.test(value || ''), [value]);
  const includesSymbol = useMemo(() => /[^\w\d]/g.test(value || ''), [value]);
  const includesNumber = useMemo(() => /[0-9]/g.test(value || ''), [value]);
  const hasRequiredLength = useMemo(() => /.{8,}$/g.test(value || ''), [value]);

  return (
    <Wrapper>
      <Item isValid={hasRequiredLength}>At least 8 characters</Item>
      <Item isValid={includesNumber}>At least one number</Item>
      <Item isValid={includesCapital}>At least one capital letter</Item>
      <Item isValid={includesSymbol}>At least one symbol</Item>
    </Wrapper>
  );
}
