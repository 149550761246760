import Chip from '../../../common/Chip';
import PointsIcon from '../../../icons/PointsIcon';
import styled from 'styled-components';
import useFeatureToggle from '../../../../hooks/useFeatureToggle';
import { useRecoilValue } from 'recoil';
import { AccountState } from '../../../../states/AppData';
import LoadingSpinner from '../../../common/LoadingSpinner';
import { useIsApiReady } from '../../../../services/ApiProvider';
import { HTMLAttributes } from 'react';
import { BREAKPOINT_MD } from '../../../../styles/Breakpoints';

const Wrapper = styled.div`
  display: none;

  ${BREAKPOINT_MD} {
    display: flex;
    gap: inherit;
  }
`;

const Loader = styled(LoadingSpinner)`
  --width: 2px;
  font-size: 0.75rem;
`;

interface IUserMetaDataProps extends HTMLAttributes<HTMLDivElement> {
  loading: boolean;
}

export default function UserMetaData(props: IUserMetaDataProps) {
  const { loading, ...htmlProps } = props;
  const featureToggles = useFeatureToggle();
  const accountState = useRecoilValue(AccountState);
  const isApiReady = useIsApiReady();

  if (isApiReady) {
    return (
      <Wrapper {...htmlProps}>
        {featureToggles.TRAINING_ACTIVE && (
          <Chip>
            <PointsIcon />
            {loading ? <Loader /> : accountState?.account?.points}
          </Chip>
        )}
      </Wrapper>
    );
  } else {
    return <></>;
  }
}
