import { useRecoilValue } from 'recoil';
import { BallerState } from '../../../states/AppData';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useBallerContractApi } from '../../../services/contract/baller';
import { StyledContainer } from '../mint';
import { INFT } from '../../../types/NFT';
import { getBallerId } from '../../../utils/getNFTId';
import styled from 'styled-components';
import { BREAKPOINT_LG, BREAKPOINT_SM, BREAKPOINT_XL } from '../../../styles/Breakpoints';
import IconLink from '../../common/IconLink';
import getOpenSeaUrl from '../../../utils/getOpenSeaUrl';
import { Button } from '../../common/Button';
import Arrow from '../../icons/Arrow';
import { useIsApiReady } from '../../../services/ApiProvider';

const Wrapper = styled.div`
  display: grid;
  gap: 1.5rem;
`;

const Container = styled(StyledContainer)`
  padding: 1.5rem;
  overflow: hidden;

  ${BREAKPOINT_SM} {
    padding: 4rem;
  }

  ${BREAKPOINT_LG} {
    grid-template-columns: 1fr 1fr;
  }

  ${BREAKPOINT_XL} {
    padding-inline: 8rem;
  }
`;

const CollectionName = styled.h1`
  font-size: 1.25rem;
  line-height: 1.3;
`;

const NFTId = styled.h2`
  font-size: 2.125rem;
  line-height: 1.3;
`;

const Information = styled.div`
  display: grid;
  gap: 1.25rem;
`;

const Table = styled.table`
  border: 1px solid var(--color-grey-800);
  border-collapse: collapse;
  font-size: 0.875rem;
`;

const Attribute = styled.td`
  padding-inline: 2.25rem;
  padding-block: 0.5rem;
  border: 1px solid var(--color-grey-800);
  border-collapse: collapse;
  text-align: center;
`;

const Image = styled.img`
  border-radius: 10px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: center;
`;

const Header = styled.div`
  display: grid;
  justify-content: space-between;
`;

const OpenseaLink = styled(IconLink)`
  grid-area: 1 / 2 / 3 / 3;
  border: 1px solid var(--color-grey-300);
  background-color: var(--color-black-900);
  color: var(--color-grey-300);
  border-radius: 0.625rem;

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary-750);
      border: 1px solid var(--color-primary-750);
      background: var(--color-primary-900);
      box-shadow:
        0 0 2px 0 var(--color-primary-750),
        0 3px 20px 0 rgba(255, 53, 25, 0.6);
    }
  }
`;

const StyledButton = styled(Button)`
  padding-inline: 1.25rem;
  display: flex;
  gap: 0.5rem;
`;

const Download = styled.button`
  font-size: 0.75rem;
  margin-inline: auto;
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  font-family: var(--font-family-headline);
  text-decoration: underline;
`;

export default function Detail() {
  const { nfts } = useRecoilValue(BallerState);
  const { ballerId } = useParams();
  const navigate = useNavigate();
  const ballerApi = useBallerContractApi();
  const isApiReady = useIsApiReady();

  const [nft, setNFT] = useState<INFT>();

  const init = useCallback(async () => {
    let nft = nfts?.find(({ name }) => getBallerId(name) === ballerId);

    if (!nft && isApiReady) {
      nft = (await ballerApi.getBallerMetadata(ballerId || ''))[0];
    }

    setNFT(nft);
  }, [nfts, ballerId, isApiReady]);

  useEffect(() => {
    if (isApiReady) {
      init();
    }
  }, [isApiReady]);

  function downloadImage(url: string) {
    fetch(url) // { mode: 'no-cors' }
      .then((response) => response.blob())
      .then((blob) => {
        console.log(blob);
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.download = url.replace(/^.*[\\\/]/, '');
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => console.log('DOWNLOAD ERROR =>', error));
  }

  const back = () => {
    navigate(-1);
  };

  return nft ? (
    <Wrapper>
      <StyledButton theme='secondary' onClick={back}>
        <Arrow />
        Back
      </StyledButton>

      <Container>
        <div>
          <Image src={nft.image} alt={nft.name} />
          <Download onClick={() => downloadImage(nft.image)}>download full resolution image</Download>
        </div>

        <Information>
          <Header>
            <CollectionName>Baller</CollectionName>

            <NFTId>#{getBallerId(nft.name)}</NFTId>

            <OpenseaLink
              icon='opensea'
              link={{
                href: getOpenSeaUrl('baller', parseInt(getBallerId(nft.name))),
                title: nft.name,
                target: '_blank',
              }}
            />
          </Header>

          <Table>
            <tbody>
              {nft?.attributes.map(({ trait_type, value }, i) => (
                <tr key={i}>
                  <Attribute>{trait_type}</Attribute>
                  <Attribute>{value}</Attribute>
                </tr>
              ))}
            </tbody>
          </Table>
        </Information>
      </Container>
    </Wrapper>
  ) : (
    <></>
  );
}
