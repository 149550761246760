// General
import styled from 'styled-components';
import { HTMLAttributes, useEffect } from 'react';

const Button = styled.button<{ open: boolean }>`
  z-index: 10;
  width: var(--size, 1.5rem);
  height: ${({ open }) => (open ? 'var(--size, 1.5rem)' : 'calc(var(--size, 1.5rem) * .75)')};
  margin-block: ${({ open }) => (open ? '0rem' : 'calc(var(--size, 1.5rem) / 8)')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition:
    height 300ms ease,
    margin 300ms ease;
  --burger-color: var(--color-text-default);
  overflow: hidden;

  @media (hover: hover) {
    &:hover {
      --burger-color: var(--color-primary-750);
    }
  }
`;

const BaseSpan = styled.div<{ open?: boolean }>`
  width: 100%;
  height: 2px;
  background: var(--burger-color);
  transition:
    transform 300ms ease,
    opacity 300ms ease;
  border-radius: 2px;
`;

const Top = styled(BaseSpan)`
  transform: translateY(${({ open }) => (open ? 'calc(var(--size, 1.5rem) / 3 + 3px)' : 0)}) rotate(${({ open }) => (open ? '45deg' : 0)});
`;

const Middle = styled(BaseSpan)`
  opacity: ${({ open }) => (open ? 0 : 1)};
  transform: translateX(${({ open }) => (open ? '100%' : 0)});
`;

const Bottom = styled(BaseSpan)`
  transform: translateY(${({ open }) => (open ? 'calc(var(--size, 1.5rem) / -3 - 3px)' : 0)}) rotate(${({ open }) => (open ? '-45deg' : 0)});
`;

interface IBurgerProps extends HTMLAttributes<HTMLButtonElement> {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function AnimatedBurgerButton({ setOpen, open, ...buttonProps }: IBurgerProps) {
  useEffect(() => {
    document.body.classList.toggle('no-scroll', open);
  }, [open]);

  return (
    <Button open={open} onClick={() => setOpen(!open)} {...buttonProps}>
      <Top open={open} />
      <Middle open={open} />
      <Bottom open={open} />
    </Button>
  );
}
