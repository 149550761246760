import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsApiReady } from '../../../services/ApiProvider';

export default function UserRoute({ children }: PropsWithChildren) {
  const isApiReady = useIsApiReady();
  const navigate = useNavigate();

  if (isApiReady) {
    return <>{children}</>;
  } else {
    navigate('/');
    return <></>;
  }
}
