import styled from 'styled-components';
import { Translations } from 'src/utils/Translations';
import { useEffect, useMemo, useState } from 'react';
import { useAccountApi } from 'src/services/account';
import ErrorIcon from 'src/components/icons/ErrorIcon';
import LoadingSpinner from 'src/components/common/LoadingSpinner';
import SuccessIcon from 'src/components/icons/SuccessIcon';
import { Button } from 'src/components/common/Button';
import LinkHandler from 'src/components/common/LinkHandler';
import Wysiwyg from 'src/components/common/Wysiwyg';
import Mooncourt from 'src/components/icons/Mooncourt';
import { AccountState } from 'src/states/AppData';
import { useRecoilValue } from 'recoil';

const Wrapper = styled.div`
  align-self: center;
  margin-block: auto;
  display: flex;
  max-width: 32rem;
  padding: 3rem 4.375rem;
  flex-direction: column;
  align-items: center;
  border-radius: 1.25rem;
  border: 1px solid var(--color-text-default);
  background: var(--color-text-highlight);
`;

const Logo = styled(Mooncourt)`
  color: var(--color-primary-750);
  font-size: 2rem;
  margin-block-end: 0.5rem;
`;

const Headline = styled.h2`
  font-size: var(--font-size-headline-md);
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin-block-end: 2rem;
`;

const Text = styled(Wysiwyg)`
  margin-block-end: 2rem;
`;

const StyledLinkHandler = styled(LinkHandler)`
  width: 100%;

  &:hover {
    text-decoration: none;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  justify-content: center;
`;

const IconWrapper = styled.div`
  display: flex;
  font-size: 3rem;
  margin-block-end: 2rem;
`;

export default function NewsletterOptIn() {
  const accountApi = useAccountApi();

  const accountState = useRecoilValue(AccountState);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  const [step, setStep] = useState<'loading' | 'success' | 'error'>('loading');

  async function handleOptIn() {
    if (token) {
      try {
        const response = await accountApi.newsletterOptIn(token);
        if (response.success) {
          setStep('success');
        } else {
          setStep('error');
        }
      } catch (error) {
        setStep('error');
        console.log('error opt-in in to newsletter:', error);
      }
    } else {
      setStep('error');
    }
  }

  useEffect(() => {
    handleOptIn();
  }, []);

  const statusTranslations = useMemo(() => {
    switch (step) {
      case 'success':
        if (accountState?.newsletter?.initialOptIn) {
          return { ...Translations.newsletter.optIn.success, text: Translations.newsletter.optIn.success.textAlreadyClaimed };
        } else {
          return Translations.newsletter.optIn.success;
        }
      case 'error':
        return Translations.newsletter.optIn.error;
      case 'loading':
        return Translations.newsletter.optIn.loading;
    }
  }, [step, accountState]);

  const statusIcon = useMemo(() => {
    switch (step) {
      case 'success':
        return <SuccessIcon style={{ color: 'var(--color-signal-green)' }} />;
      case 'error':
        return <ErrorIcon style={{ color: 'var(--color-signal-red)' }} />;
      case 'loading':
        return <LoadingSpinner />;
    }
  }, [step]);

  return (
    <Wrapper>
      <Logo />
      <Headline>{statusTranslations.headline}</Headline>
      <Text content={statusTranslations.text} />
      <IconWrapper>{statusIcon}</IconWrapper>
      <StyledLinkHandler to={'/'}>
        <StyledButton disabled={step === 'loading'}>{statusTranslations.buttonLabel}</StyledButton>
      </StyledLinkHandler>
    </Wrapper>
  );
}
