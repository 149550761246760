import styled from 'styled-components';
import { FontFamilies } from '../../../../styles/FontFamilies';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-inline-start: 1px solid var(--color-grey-800);
  border-block-start: 1px solid var(--color-grey-800);
`;

const Field = styled.div`
  display: grid;
  place-content: center;
  font-size: 0.875rem;
  padding: 0.25rem 0.625rem;
  border-inline-end: 1px solid var(--color-grey-800);
  border-block-end: 1px solid var(--color-grey-800);
`;

const Row = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: subgrid;
`;

const Header = styled(Row)`
  font-family: ${FontFamilies.headline};
  font-size: 1rem;
  font-weight: 700;

  ${Field} {
    padding: 0.625rem;
  }
`;

const SizeData = [
  { eu: 35.5, uk: 3, usm: 3.5, usw: 5 },
  { eu: 36, uk: 3.5, usm: 4, usw: 5.5 },
  { eu: 36.5, uk: 4, usm: 4.5, usw: 6 },
  { eu: 37.5, uk: 4.5, usm: 5, usw: 6.5 },
  { eu: 38, uk: 5, usm: 5.5, usw: 7 },
  { eu: 38.5, uk: 5.5, usm: 6, usw: 7.5 },
  { eu: 39, uk: 6, usm: 6.5, usw: 8 },
  { eu: 40, uk: 6, usm: 7, usw: 8.5 },
  { eu: 40.5, uk: 6.5, usm: 7.5, usw: 9 },
  { eu: 41, uk: 7, usm: 8, usw: 9.5 },
  { eu: 42, uk: 7.5, usm: 8.5, usw: 10 },
  { eu: 42.5, uk: 8, usm: 9, usw: 10.5 },
  { eu: 43, uk: 8.5, usm: 9.5, usw: 11 },
  { eu: 44, uk: 9, usm: 10, usw: 11.5 },
  { eu: 44.5, uk: 9.5, usm: 10.5, usw: 12 },
  { eu: 45, uk: 10, usm: 11, usw: 12.5 },
  { eu: 45.5, uk: 10.5, usm: 11.5, usw: 13 },
  { eu: 46, uk: 11, usm: 12, usw: 13.5 },
  { eu: 47, uk: 11.5, usm: 12.5, usw: 14 },
  { eu: 47.5, uk: 12, usm: 13, usw: 14.5 },
  { eu: 48, uk: 12.5, usm: 13.5, usw: 15 },
  { eu: 48.5, uk: 13, usm: 14, usw: 15.5 },
  { eu: 49, uk: 13.5, usm: 14.5, usw: 16 },
  { eu: 49.5, uk: 14, usm: 15, usw: 16.5 },
  { eu: 50, uk: 14.5, usm: 15.5, usw: 17 },
  { eu: 50.5, uk: 15, usm: 16, usw: 17.5 },
  { eu: 51, uk: 15.5, usm: 16.5, usw: 18 },
  { eu: 51.5, uk: 16, usm: 17, usw: 18.5 },
  { eu: 52, uk: 16.5, usm: 17.5, usw: 19 },
  { eu: 52.5, uk: 17, usm: 18, usw: 19.5 },
  { eu: 53, uk: 17.5, usm: 18.5, usw: 20 },
  { eu: 53.5, uk: 18, usm: 19, usw: 20.5 },
  { eu: 54, uk: 18.5, usm: 19.5, usw: 21 },
  { eu: 54.5, uk: 19, usm: 20, usw: 21.5 },
  { eu: 55, uk: 19.5, usm: 20.5, usw: 22 },
  { eu: 55.5, uk: 20, usm: 21, usw: 22.5 },
  { eu: 56, uk: 20.5, usm: 21.5, usw: 23 },
  { eu: 56.5, uk: 21, usm: 22, usw: 23.5 },
];

export default function DetailPageSizeTable() {
  return (
    <Wrapper>
      <Header>
        <Field>EU</Field>
        <Field>UK</Field>
        <Field>US (m)</Field>
        <Field>US (w)</Field>
      </Header>

      {SizeData.map(({ eu, uk, usm, usw }, index) => (
        <Row key={index}>
          <Field>{eu}</Field>
          <Field>{uk}</Field>
          <Field>{usm}</Field>
          <Field>{usw}</Field>
        </Row>
      ))}
    </Wrapper>
  );
}
