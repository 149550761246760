import InfoIcon from '../icons/InfoIcon';
import CloseIcon from '../icons/CloseIcon';
import styled, { css } from 'styled-components';
import { DialogHTMLAttributes, ForwardedRef, forwardRef } from 'react';
import { BREAKPOINT_MD } from '../../styles/Breakpoints';
import Wysiwyg from './Wysiwyg';

export const DialogStyles = css`
  z-index: 1;
  padding: 1rem;
  border-radius: 6px;
  color: var(--color-text-default);
  border: 1px solid var(--color-text-default);
  background: var(--color-text-highlight);
  box-shadow:
    0 0 10px 0 var(--color-text-default),
    0 0 30px 0 var(--color-text-highlight);
`;

const Wrapper = styled.dialog`
  ${DialogStyles};
  align-self: center;
  justify-self: center;
  margin: var(--content-spacing);

  &::backdrop {
    background: rgba(0, 0, 0, 0.01);
    backdrop-filter: blur(5px);
  }

  ${BREAKPOINT_MD} {
    width: 32rem;
  }
`;

const Body = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledInfoIcon = styled(InfoIcon)`
  font-size: 1.25rem;
`;

const Headline = styled.h5`
  font-size: 1.125rem;
  line-height: 1;
  text-transform: uppercase;
  margin-block-start: 0.125rem;
`;

const CloseButton = styled.button`
  display: flex;
  margin-inline-start: auto;

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary-750);
    }
  }
`;

const Content = styled.div`
  display: flex;
  font-size: 0.875rem;
`;

const StyledWysiwyg = styled(Wysiwyg)`
  margin: 0;
  gap: 0;
  white-space: normal;
  line-height: 1.25;

  p {
    font-weight: 300;
  }
`;

interface IDialogBodyProps {
  translation: {
    headline: string;
    body: string;
  };
  toggleDialog?: () => void;
}

interface IDialogProps extends DialogHTMLAttributes<HTMLDialogElement>, IDialogBodyProps {
  //
}

const Dialog = forwardRef(function (props: IDialogProps, ref: ForwardedRef<HTMLDialogElement>) {
  const { translation, toggleDialog, ...dialogProps } = props;

  return (
    <Wrapper {...dialogProps} ref={ref}>
      <DialogBody translation={translation} toggleDialog={toggleDialog} />
    </Wrapper>
  );
});

export const DialogBody = (props: IDialogBodyProps) => {
  const { translation, toggleDialog } = props;
  const { headline, body } = translation;

  return (
    <Body>
      <Header>
        <StyledInfoIcon />

        <Headline>{headline}</Headline>

        {toggleDialog && (
          <CloseButton onClick={toggleDialog}>
            <CloseIcon />
          </CloseButton>
        )}
      </Header>

      <Content>
        <StyledWysiwyg content={body} />
      </Content>
    </Body>
  );
};

export default Dialog;
