import styled from 'styled-components';
import InfoIcon from '../icons/InfoIcon';
import Dialog, { DialogBody, DialogStyles } from './Dialog';
import { useRef } from 'react';

const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

const MobileInfoIcon = styled(InfoIcon)`
  display: none;
  cursor: pointer;

  @media (hover: hover) {
    display: initial;

    &:hover {
      color: var(--color-primary-750);

      + * {
        display: flex;
      }
    }
  }
`;

const DesktopButton = styled.button`
  display: flex;

  @media (hover: hover) {
    display: none;
  }
`;

export const AbsoluteWrapper = styled.div`
  ${DialogStyles};
  display: none;
  position: absolute;
  top: calc(100% + 2rem);
  right: calc(100% - 2.5rem);
  width: 32rem;
`;

interface ITooltipProps {
  translation: {
    headline: string;
    body: string;
  };
  className?: string;
}

export default function Tooltip(props: ITooltipProps) {
  const { translation, className } = props;
  const dialogRef = useRef<HTMLDialogElement>(null);

  function toggleDialog() {
    if (dialogRef?.current) {
      dialogRef.current.open ? dialogRef.current.close() : dialogRef.current.showModal();
    }
  }

  return (
    <Wrapper className={className}>
      <DesktopButton>
        <InfoIcon onClick={toggleDialog} />
      </DesktopButton>

      <MobileInfoIcon />

      <AbsoluteWrapper>
        <DialogBody translation={translation} />
      </AbsoluteWrapper>

      <Dialog ref={dialogRef} translation={translation} toggleDialog={toggleDialog} />
    </Wrapper>
  );
}
