// General
import { FC, SVGProps } from 'react';

const ErrorIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM9.9499 21.2638L14.9636 16.2501L10.0135 11.3L11.4277 9.88575L16.3779 14.8359L21.2636 9.95013L22.6778 11.3643L17.7921 16.2501L22.7414 21.1995L21.3272 22.6137L16.3779 17.6643L11.3641 22.6781L9.9499 21.2638Z'
      fill='currentColor'
    />
  </svg>
);

export default ErrorIcon;
