import styled from 'styled-components';
import { PropsWithChildren } from 'react';
import ActiveIndicatorIcon from 'src/components/icons/ActiveIndicatorIcon';
import PassiveIndicatorIcon from 'src/components/icons/PassiveIndicatorIcon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Indicator = styled.span<{ active: boolean }>`
  font-size: 1.5rem;
  line-height: 1;
  height: 1.5rem;
  color: var(--color-${({ active }) => (active ? 'primary-750' : 'grey-650')});
`;

const Text = styled.p<{ active: boolean }>`
  font-size: var(--font-size-body-sm);
  line-height: 1.3;
`;

interface IAttributeProps extends PropsWithChildren {
  active: boolean;
}

export default function SubscriptionAttribute(props: IAttributeProps) {
  const { active, children } = props;

  return (
    <Wrapper>
      <Indicator active={active}>{active ? <ActiveIndicatorIcon /> : <PassiveIndicatorIcon />}</Indicator>
      <Text active={active}>{children}</Text>
    </Wrapper>
  );
}
