import styled, { css } from 'styled-components';
import { ILink } from '../../../types/link';
import Mooncourt from '../../icons/Mooncourt';
import OpenSeaIcon from '../../icons/OpenSeaIcon';
import {
  CardAttribute,
  CardAttributes,
  CardBody,
  CardContainer,
  CardFooter,
  CardHeader,
  CardImageWrapper,
  CardOpenSeaLink,
  CardTitle,
  CardWrapper,
  InnerCardWrapper,
} from './CardStyles';
import { Translations } from '../../../utils/Translations';
import { useAccount } from 'wagmi';
import { useMemo } from 'react';

const StyledCardWrapper = styled(CardWrapper)`
  position: relative;
  cursor: ${({ hasHover }) => (hasHover ? 'pointer' : 'initial')};

  ${({ hasHover }) =>
    !hasHover &&
    css`
      border-color: var(--color-grey-650);

      ::after {
        content: '';
        z-index: 1;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
      }
    `};
`;

const SelectIndicator = styled.input.attrs({ type: 'checkbox' })<{ selected: boolean }>`
  position: absolute;
  z-index: 2;
  display: grid;
  top: 8cqw;
  right: 8cqw;
  width: 8cqw;
  height: 8cqw;
  background: var(--select-background, linear-gradient(180deg, #ffe7e7 0%, rgba(255, 255, 255, 0) 100%));
  border-radius: 100%;
  border: 1.5px solid var(--color-text-default);
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.7));
  pointer-events: auto;

  ::after {
    content: '✓';
    font-size: 6cqw;
    line-height: 8cqw;
    padding-inline: 1.33cqw;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }

  ${({ selected }) =>
    selected &&
    css`
      --select-background: linear-gradient(189.04deg, #f63f21 5.93%, rgba(246, 63, 33, 0) 94.39%);

      :after {
        opacity: 1;
      }
    `};
`;

const IndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const ConnectionIndicator = styled.span<{ isConnected: boolean }>`
  display: flex;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  background: var(--color-signal-${({ isConnected }) => (isConnected ? 'green' : 'red')});
`;

export interface IBoosterCardProps {
  id: number;
  title: string;
  image: string;
  selected: boolean;
  toggleSelect?: () => void;
  openseaLink: ILink;
  wallet: string;
}

export default function BoosterCard(props: IBoosterCardProps) {
  const { id, title, image, selected, toggleSelect, openseaLink, wallet } = props;
  const { address } = useAccount();

  const isOnConnectedWallet = useMemo(() => address === wallet, [address, wallet]);

  function handleClick(e: any) {
    e.stopPropagation();
    toggleSelect && isOnConnectedWallet && toggleSelect();
  }

  return (
    <CardContainer>
      <StyledCardWrapper onClick={handleClick} hasHover={isOnConnectedWallet}>
        {isOnConnectedWallet && <SelectIndicator selected={selected} />}

        <InnerCardWrapper>
          <CardImageWrapper itemAvailable={true}>
            <video src={image} muted autoPlay loop playsInline />
          </CardImageWrapper>

          <CardBody>
            <CardHeader>
              <CardTitle>{title}</CardTitle>
            </CardHeader>

            <CardAttributes>
              <CardAttribute>
                <p>{Translations.cards.attributes.wallet}</p>
                <IndicatorWrapper>
                  <ConnectionIndicator isConnected={isOnConnectedWallet} />
                  {wallet.slice(0, 4)}...{wallet.slice(wallet.length - 4, wallet.length)}
                </IndicatorWrapper>
              </CardAttribute>

              <CardAttribute>
                <p>{Translations.cards.attributes.id}</p>
                <p>#{id}</p>
              </CardAttribute>
            </CardAttributes>

            <CardFooter>
              <CardOpenSeaLink to={openseaLink.href} {...openseaLink} onClick={(e: any) => e.stopPropagation()}>
                <OpenSeaIcon />
              </CardOpenSeaLink>

              <Mooncourt />
            </CardFooter>
          </CardBody>
        </InnerCardWrapper>
      </StyledCardWrapper>
    </CardContainer>
  );
}
