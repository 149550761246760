import styled from 'styled-components';
import AddWalletCard from './subcomponents/AddWalletCard';
import { useCallback, useContext, useState } from 'react';
import ConnectWallet from '../../layout/WalletConnect/ConnectWallet';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';
import { useRecoilState } from 'recoil';
import { WalletState } from '../../../states/AppData';
import WalletCard from './subcomponents/WalletCard';
import { useUserApi } from '../../../services/user';
import useToast from '../../../hooks/useToast';
import { Notifications } from '../../layout/app/Layout';
import ConfirmDeleteWalletModal from './subcomponents/ConfirmDeleteWalletModal';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1.5rem;
`;

export default function WalletTab() {
  const [openConnectWalletModal, setOpenConnectWalletModal] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

  const [walletState, setWalletState] = useRecoilState(WalletState);
  const notifications = useContext(Notifications);

  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const { signMessageAsync } = useSignMessage();
  const userApi = useUserApi();
  const { addErrorToast } = useToast();

  const handleNewWalletClick = useCallback(() => {
    if (isConnected) {
      disconnect();
    }
    setOpenConnectWalletModal(true);
  }, [isConnected, disconnect]);

  const handleDeleteWallet = useCallback(async () => {
    if (address) {
      try {
        const { message, nonce } = await userApi.getNonce();
        const signedMessage = await signMessageAsync({ message });
        const verifyNonceResponse = await userApi.verifyNonce(address, signedMessage, nonce);
        const disconnectResponse = await userApi.disconnectWallet({
          wallet: address,
          walletAuthorization: (verifyNonceResponse as any).token,
        });
        setWalletState(disconnectResponse.wallets);
        disconnect();
      } catch (error) {
        console.log('error disconnecting / deleting wallet:', error);
        if (notifications) {
          addErrorToast(notifications.internalServerError);
        }
      } finally {
        setOpenConfirmDeleteModal(false);
      }
    }
  }, [address, disconnect, notifications]);

  return (
    <Wrapper>
      {walletState?.map((item, index) => (
        <WalletCard
          {...item}
          key={index}
          onConnect={() => setOpenConnectWalletModal(true)}
          onDelete={() => setOpenConfirmDeleteModal(true)}
        />
      ))}

      <AddWalletCard onClick={handleNewWalletClick} />

      {openConnectWalletModal && <ConnectWallet close={() => setOpenConnectWalletModal(false)} />}
      {openConfirmDeleteModal && <ConfirmDeleteWalletModal onClose={() => setOpenConfirmDeleteModal(false)} onClick={handleDeleteWallet} />}
    </Wrapper>
  );
}
