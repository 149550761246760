import { css } from 'styled-components';
import { BREAKPOINT_LG, BREAKPOINT_XL } from './Breakpoints';

export const Headline1 = css`
  font-size: 3.125rem;
  line-height: 1.25;
  font-weight: 600;

  ${BREAKPOINT_LG} {
    font-size: 6.5rem;
  }
`;

export const Headline2 = css`
  font-size: 3.125rem;
  line-height: 1.25;
  font-weight: 600;

  ${BREAKPOINT_LG} {
    font-size: 6.5rem;
  }
`;

export const Headline3 = css`
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: 600;

  ${BREAKPOINT_LG} {
    font-size: 1.5rem;
  }
`;

export const Headline4 = css`
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: 600;

  ${BREAKPOINT_LG} {
    font-size: 1.5rem;
  }
`;

export const Headline5 = css`
  font-size: 1rem;
  line-height: 1.25;
  font-weight: 600;

  ${BREAKPOINT_LG} {
    font-size: 1rem;
  }
`;

export const Headline6 = css`
  font-size: 1rem;
  line-height: 1.25;
  font-weight: 600;
`;

export const Body1 = css`
  font-size: 1rem;
  line-height: 1.25;

  ${BREAKPOINT_LG} {
    font-size: 1rem;
  }

  ${BREAKPOINT_XL} {
    font-size: 1.125rem;
  }
`;

export const Body1Bold = css`
  ${Body1};
  font-weight: 700;
`;

export const Body2 = css`
  font-size: 0.75rem;
  line-height: 1.25;
`;

export const Body2Bold = css`
  ${Body2};
  font-weight: 700;
`;
